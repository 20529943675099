import React, { useCallback, useEffect, useMemo } from 'react';

import { renderToStaticMarkup } from 'react-dom/server';

import { BlockContent } from './BlockContent/BlockContent';
import useAiStore, { type Segment } from '../../Hooks/useAiStore';

type AiTextSegmentProps = {
    data: Segment;
    previousKey?: string;
};

const AiTextSegment: React.FC<AiTextSegmentProps> = ({ data, previousKey }) => {
    const { id, text, blocks, traceBlocks, isSkipped, originText, issue } = data;
    const isSegmentLoading = useAiStore((store) => store.isSegmentLoading);
    const editorNode: HTMLDivElement | null = useAiStore((store) => store.resultEditorNode);

    // const segments = useAiStore(store => store.segments);
    const requestsRemaining = useAiStore((store) => store.requestsRemaining);
    // const handleSetSingleCorrectionActiveIndex = useAiStore((store) => store.handleSetSingleCorrectionActiveIndex);
    // const isSingleCorrectionMode = useAiStore((store) => store.singleCorrectionActiveIndex !== -1);
    // const isParaphrasingModeActive = useAiStore((store) => !!store.paraphrasingMode);
    // const selectedSegmentId = useAiStore((store) => store.selectedSegmentId);
    const isHighlightModeActive = useAiStore((store) => store.isHighlightModeActive);
    // const isSingleScreenMode = useAiStore((store) => store.isSingleScreenMode);
    // const isActive = useAiStore((store) => {
    //     const activeIndex = store.singleCorrectionActiveIndex;
    //
    //     if (activeIndex !== -1) {
    //         const currentIndex = store.segments.findIndex((segment) => segment.id === id);
    //
    //         return currentIndex === activeIndex;
    //     }
    //
    //     return false;
    // });
    const isCorrected = !!traceBlocks?.length;
    const hasIssue = !!issue;
    // const isSelected = id === selectedSegmentId;
    // const isClickable = !isActive && !!(isCorrected || issue);

    const segmentNode = useMemo(() => {
        const spanNode = document.createElement('span');

        spanNode.classList.add('text-segment');

        if (id) {
            spanNode.id = id;
        }

        return spanNode;
    }, [id]);

    const handleSegmentClick = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();

        if (hasIssue) {
            useAiStore.setState({ activeSegmentId: id });
        } else {
            useAiStore.setState({ activeSegmentId: null });
        }
    }, [id, hasIssue]);

    const handleSegmentHighlight = useCallback((event) => {
        useAiStore.setState({ selectedSegmentId: id });
    }, [id]);

    const isLoading = isSegmentLoading(id);
    const isBlured = requestsRemaining === 0 && !isCorrected;

    // append dom node
    useEffect(() => {
        const previousSegmentNode = previousKey && editorNode?.querySelector(`#${previousKey}`);

        if (previousSegmentNode) {
            previousSegmentNode.insertAdjacentElement('afterend', segmentNode);
        } else {
            editorNode?.insertAdjacentElement('afterbegin', segmentNode);
        }

    }, [editorNode, previousKey, segmentNode]);


    // cleanup dom node
    useEffect(() => {

        return () => {
            segmentNode?.remove();
        };
    }, [editorNode, segmentNode]);

    useEffect(() => {
        const blocksContent = isSkipped && originText ?
            renderToStaticMarkup(<>{originText}</>) :
            renderToStaticMarkup(blocks?.length ? <BlockContent blocks={isHighlightModeActive ? traceBlocks : blocks} /> : <>{text}</>);

        segmentNode && (segmentNode.innerHTML = blocksContent);
    }, [blocks, traceBlocks, isSkipped, originText, segmentNode, text, isHighlightModeActive]);

    useEffect(() => {
        if (isLoading) {
            segmentNode?.classList.add('loading');
        } else {
            segmentNode?.classList.remove('loading');
        }

    }, [isLoading, segmentNode]);

    useEffect(() => {
        if (isBlured) {
            segmentNode?.classList.add('blured');
        } else {
            segmentNode?.classList.remove('blured');
        }

    }, [isBlured, segmentNode]);

    useEffect(() => {
        if (hasIssue) {
            segmentNode?.classList.add('issue');
        } else {
            segmentNode?.classList.remove('issue');
        }

    }, [hasIssue, segmentNode]);

    useEffect(() => {
        segmentNode?.addEventListener('click', handleSegmentClick);
        segmentNode?.addEventListener('mouseenter', handleSegmentHighlight);

        return () => {
            segmentNode?.removeEventListener('click', handleSegmentClick);
            segmentNode?.removeEventListener('mouseenter', handleSegmentHighlight);
        };
    }, [handleSegmentClick, handleSegmentHighlight, segmentNode]);

    return null;
};

export default AiTextSegment;