import styled, { css } from 'styled-components';

import { Colors, MediaBreakpoints } from '../../Styles';

const wrapperStyles = css<{ isActive?: boolean }>`
    flex: 0 0 100%;
    padding: 0 15px;
    box-sizing: border-box;
    position: static;
    inset: 0;
    background-color: ${Colors.white};
    z-index: 1;

    ${({ isActive }) => isActive && css`
        position: absolute;
        z-index: 2;
    `};

    @media screen and ${MediaBreakpoints.medium} {
        padding: 0 20px 0 25px;
    }

    @media screen and ${MediaBreakpoints.large} {
        position: relative;
        padding: 0 30px 0 40px;
        flex: 0 0 50%;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
`;

export const EditorWrapper = styled.div<{ isActive?: boolean }>`
    ${wrapperStyles};

    @media screen and ${MediaBreakpoints.large} {
        border-right: 1px solid ${Colors.iron};
    }
`;

export const ResultWrapper = styled.div<{ isActive?: boolean }>`
    ${wrapperStyles};
`;