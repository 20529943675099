import * as React from 'react';

export const MenuSpellcheckIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" {...props}>
        <g fill="none" fillRule="evenodd">
            <path fill="#D8D8D8" fillOpacity="0" d="M0 0H36V36H0z" transform="translate(-20 -95) translate(20 95)" />
            <g fill="#333" fillRule="nonzero">
                <path
                    d="M13.517.274l.274.001 5.934 5.755v4.323l-1.451 1.45V7.05h-3.835c-.872 0-1.588-.67-1.66-1.522l-.007-.144v-3.66H4c-1.204 0-2.19.935-2.27 2.12L1.725 4v18c0 1.204.935 2.19 2.12 2.27l.155.005h12c1.204 0 2.19-.935 2.27-2.12l.005-.155v-6.354l1.45-1.45V22c0 1.99-1.562 3.617-3.527 3.72l-.198.005H4c-1.99 0-3.617-1.562-3.72-3.527L.275 22V4C.275 2.01 1.837.383 3.802.28L4 .275h9.475l.042-.001zm.705 2.476v2.635c0 .08.043.15.107.187l.052.021.058.008h2.634L14.222 2.75z"
                    transform="translate(-20 -95) translate(20 95) translate(8 5)" />
                <path
                    d="M10.653 14.775c.316 0 .572.325.572.725 0 .367-.215.67-.494.718l-.078.007H4.347c-.316 0-.572-.325-.572-.725 0-.367.215-.67.494-.718l.078-.007h6.306zM14.5 10.775c.4 0 .725.325.725.725 0 .367-.273.67-.627.718l-.098.007h-10c-.4 0-.725-.325-.725-.725 0-.367.273-.67.627-.718l.098-.007h10z"
                    transform="translate(-20 -95) translate(20 95) translate(8 5)" />
            </g>
            <path fill="#333" fillRule="nonzero"
                  d="M33.637 8.625c.648 0 1.273.253 1.74.718l.28.28c.957.958.957 2.523 0 3.48L23.323 25.438c-.278.278-.535.42-1.184.707l-.258.11c-.356.149-.764.303-1.221.464-.437.153-.888.303-1.33.442l-.582.18c-.315.093-.656.001-.882-.237-.227-.238-.3-.584-.192-.892l.423-1.176c.125-.339.253-.677.38-1.005l.235-.594.1-.242.188-.445.175-.39.16-.333.075-.144c.041-.076.092-.145.153-.205L31.897 9.344c.466-.466 1.09-.719 1.74-.719zm0 1.292c-.304 0-.597.121-.821.346L20.548 22.53c-.048.09-.097.187-.148.294l-.159.348c-.028.062-.056.126-.084.193l-.178.426-.093.232-.3.772-.216.578-.114.307.093.097.425-.132.454-.154.516-.187.24-.09.435-.176.195-.083.114-.052.227-.113.204-.112.157-.095c.042-.028.073-.05.088-.065l12.334-12.333c.46-.461.46-1.182 0-1.642l-.28-.28c-.225-.225-.518-.346-.821-.346z"
                  transform="translate(-20 -95) translate(20 95)" />
        </g>
    </svg>
);
