import React, { useCallback } from 'react';

import * as SC from './AiResultPadHeader.styled';
import useAiStore from '../../../Hooks/useAiStore';
import { ArrowLeftIcon } from '../../Icons';
import { IconKnob, KnobAppearance, KnobSize } from '../../Knob';
import { AiResultPadToggle } from '../AiResultPadToggle';

type AiResultPadHeaderProps = {};

const AiResultPadHeader: React.FC<AiResultPadHeaderProps> = () => {
    const isSingleScreenMode = useAiStore((store) => store.isSingleScreenMode);
    const segments = useAiStore((store) => store.segments);

    const handleBackClick = useCallback(() => {
        useAiStore.setState({ isSingleScreenResultMode: false });
    }, []);

    return <SC.Wrapper>
        {isSingleScreenMode && <IconKnob onClick={handleBackClick} appearance={KnobAppearance.Secondary} size={KnobSize.Large} icon={<ArrowLeftIcon />} />}
        {!!segments.length && <AiResultPadToggle />}
    </SC.Wrapper>;
};

export default AiResultPadHeader;
