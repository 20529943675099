import styled from 'styled-components';

import { Colors, Fonts } from '../../Styles';

export const Wrapper = styled.div`
    display: flex;
    gap: 10px;
`;

export const Radio = styled.label`
    width: 30px;
    height: 30px;
    flex: 0 0 auto;
    border: 2px solid ${Colors.mineShaft};
    border-radius: 50%;
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;

    span {
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: ${Colors.mineShaft};
        opacity: 0;
        transition: opacity 0.2s;
    }

    input {
        display: none;
    }

    &:hover {
        opacity: 0.5;
    }

    input:checked ~ span {
        opacity: 1;
    }
`;

export const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    line-height: 24px;
`;

export const Label = styled.label`
    font-family: ${Fonts.theinhardt};
    font-size: 15px;
    line-height: normal;
    cursor: pointer;
`;
