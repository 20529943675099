import { useCallback, useEffect, useMemo } from 'react';

import useAiStore from './useAiStore';

const useAiScrollSync = () => {
    const isResultViewInteractive = useAiStore(store => store.isResultViewInteractive);
    const editorNode = useAiStore(store => store.editorNode);
    const resultEditorNode = useAiStore(store => store.resultEditorNode);
    const selectedSegmentId = useAiStore(store => store.selectedSegmentId);

    const editorNodes = [editorNode, resultEditorNode];
    const segmentSelectors = [`#editor-${selectedSegmentId}`, `#${selectedSegmentId}`];

    const [rootEditorNode, targetEditorNode] = isResultViewInteractive ? editorNodes.reverse() : editorNodes;
    const [rootSelector, targetSelector] = isResultViewInteractive ? segmentSelectors.reverse() : segmentSelectors;

    const rootSegment = useMemo(() => {
        if (rootEditorNode && selectedSegmentId) {
            return rootEditorNode.querySelector(rootSelector) as HTMLSpanElement | null;
        }

        return null;
    }, [rootEditorNode, selectedSegmentId, rootSelector]);

    const targetSegment = useMemo(() => {
        if (targetEditorNode && selectedSegmentId) {
            return targetEditorNode.querySelector(targetSelector) as HTMLSpanElement | null;
        }

        return null;
    }, [targetEditorNode, selectedSegmentId, targetSelector]);

    const handleScrollSync = useCallback((event?: Event) => {
        if (!rootEditorNode || !targetEditorNode) return;

        const rootWrapper = rootEditorNode.offsetParent as HTMLDivElement;
        const targetWrapper = targetEditorNode.offsetParent as HTMLDivElement;

        const rootScrollTop = rootWrapper.scrollTop;

        if (rootSegment && targetSegment) {
            const rootTop = rootSegment?.offsetTop;
            const targetTop = targetSegment?.offsetTop;

            targetWrapper.scrollTop = rootScrollTop - rootTop + targetTop;
        } else {
            targetWrapper.scrollTop = rootScrollTop;
        }
    }, [rootEditorNode, rootSegment, targetEditorNode, targetSegment]);

    useEffect(() => {
        const rootEditorWrapper = rootEditorNode?.offsetParent as HTMLDivElement | null;

        rootEditorWrapper?.addEventListener('scroll', handleScrollSync);

        return () => {
            rootEditorWrapper?.removeEventListener('scroll', handleScrollSync);
        };
    }, [rootEditorNode, handleScrollSync]);
};

export default useAiScrollSync;