import React, { useCallback, useEffect, useState } from 'react';

import * as SC from './AiPadSettings.styled';
import useAiStore, { ParaphrasingMode } from '../../../Hooks/useAiStore';
import { CheckIcon } from '../../Icons';
import { CrossSimpleIcon } from '../../Icons/CrossSimpleIcon';
import { IconKnob, KnobAppearance, KnobSize } from '../../Knob';
import { Radio } from '../../Radio';
import { MODE_ITEMS, TEXT_CATEGORY_ITEMS } from '../AiPadHeader/config';

type AiPadSettingsProps = {
    handleClose: () => void;
};

enum SettingsMode {
    Paraphrasing = 'paraphrasing',
    TextCategory = 'textCategory',
}

const AiPadSettings: React.FC<AiPadSettingsProps> = ({ handleClose }) => {
    const [paraphrasingMode, setParaphrasingMode] = useAiStore(store => [store.paraphrasingMode, store.setParaphrasingMode]);
    const isSingleScreenMobileMode = useAiStore(store => store.isSingleScreenMobileMode);
    const [settingsMode, setSettingsMode] = useState<SettingsMode>(SettingsMode.Paraphrasing);

    useEffect(() => {
        if (!isSingleScreenMobileMode) {
            handleClose();
        }
    }, [handleClose, isSingleScreenMobileMode]);

    const handleSetParaphrasingMode = useCallback(() => {
        setSettingsMode(SettingsMode.Paraphrasing);
    }, []);

    // const handleSetTextCategoryMode = useCallback(() => {
    //     setSettingsMode(SettingsMode.Paraphrasing);
    // }, []);

    const handleItemClick = (value?: string | null) => () => {
        if (typeof value !== 'undefined') {
            if (settingsMode === SettingsMode.TextCategory) {
                // TODO: future implementation
            } else {
                setParaphrasingMode(value as ParaphrasingMode);
            }
        }
        handleClose();
    };

    const currentItems = settingsMode === SettingsMode.TextCategory ? TEXT_CATEGORY_ITEMS : MODE_ITEMS;
    const activeValue = settingsMode === SettingsMode.TextCategory ? paraphrasingMode : paraphrasingMode; //TODO: future implementation

    return <SC.Wrapper>
        <SC.Header>
            <Radio
                name="settingsMode"
                label="Schreibstil"
                defaultChecked={settingsMode === SettingsMode.Paraphrasing}
                onChange={handleSetParaphrasingMode}
            />
            {/*<Radio*/}
            {/*    name="settingsMode"*/}
            {/*    label="Textkategorie"*/}
            {/*    disabled*/}
            {/*    defaultChecked={settingsMode === SettingsMode.TextCategory}*/}
            {/*    onChange={handleSetTextCategoryMode}*/}
            {/*/>*/}
            <SC.CloseIconWrapper>
                <IconKnob
                    onClick={handleClose}
                    appearance={KnobAppearance.Secondary}
                    size={KnobSize.Large}
                    icon={<CrossSimpleIcon width={17} height={17} />}
                />
            </SC.CloseIconWrapper>
        </SC.Header>
        <SC.Content>
            <SC.Panel>
                {currentItems.map((item, i) => {
                    if (item.name) {
                        const itemKey = item.value ?? `key_${i}`;
                        const isActive = item.value === activeValue;

                        if (item.name === 'separator') {
                            return <SC.Separator key={itemKey} />;
                        }

                        return <SC.Item key={itemKey} isActive={isActive} onClick={handleItemClick(item.value)}>
                            <div>
                                <div>{item.name}</div>
                                {!!item.description && <SC.ItemDescription>{item.description}</SC.ItemDescription>}
                            </div>
                            <SC.ItemCheckIcon className="settings-check-icon">
                                <CheckIcon />
                            </SC.ItemCheckIcon>
                        </SC.Item>;
                    }

                    return null;
                })}
            </SC.Panel>
        </SC.Content>
    </SC.Wrapper>;
};

export default AiPadSettings;
