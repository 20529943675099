import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import * as SC from './Settings.styled';
import { CheckSettingsForm, CheckSettingsFormProps } from '../../Components/CheckSettings/CheckSettingsForm';
import { updateCheckSettingsAction } from '../../Store/UserState';
import { ErrorWrapper } from '../../Util/ErrorWrapper';


const Settings: React.FC = () => {
    const dispatch = useDispatch();

    const handleSubmit: CheckSettingsFormProps['onSubmit'] = useCallback((settings) => dispatch(updateCheckSettingsAction({ settings })), [dispatch]);

    return (
        <SC.Wrapper>
            <ErrorWrapper>
                <CheckSettingsForm onSubmit={handleSubmit} />
            </ErrorWrapper>
        </SC.Wrapper>
    );
};

export default Settings;
