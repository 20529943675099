import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import * as SC from './Rim.styled';
import { RimActionButton } from './RimActionButton';
import useCorrectionStore from '../../Hooks/useCorrectionStore';
import useOneClickStore, { selectBufferText } from '../../Hooks/useOneClickStore';
import { resetAdviceAction } from '../../Store/AdviceState';
import { resetPadAction, selectText, selectTextLength } from '../../Store/PadState';
import { selectFeatureFlags } from '../../Store/SettingsState';
import { selectUserStateFromMainState, UserRole } from '../../Store/UserState';
import '../../Styles/component/authoring/rim/rim.sass';
import '../../Styles/component/form/knob/knob.sass';
import { DocumentIdentifier } from '../../Util/DocumentIdentifier';
import { formatToThousandSeparator } from '../../Util/formatingUtils';
import { GTMDataLayerPush, gtmEventTypes, sendGTMEvent } from '../../Util/GoogleTagManager';
import { isInternetExplorer, PlatformTypes } from '../../Util/PlatformUtil';
import { ToastOverlay, toastRouteGeneral, toastRouteLite, toastType } from '../../Util/ToastOverlay';
import { hasAnyFeature, hasFeature, hasRole } from '../../Util/UserUtils';
import { ArrowRightIcon } from '../Icons';
import PadBanner from '../PadBanner/PadBanner';
import { RunCheck } from '../RunCheck/RunCheck';

export const Rim: React.FC = () => {
    const [characterLimitReached, setCharacterLimitReached] = useState(false);
    const [copiedToastVisibility, setCopiedToastVisibility] = useState(false);
    const [deleteTextToastVisibility, setDeleteTextToastVisibility] = useState(false);
    const [characterLimitToastVisibility, setCharacterLimitToastVisibility] = useState(false);

    const text = useSelector(selectText);
    const classicTextLength = useSelector(selectTextLength);
    const user = useSelector(selectUserStateFromMainState);
    const featureFlags = useSelector(selectFeatureFlags);
    const isNativeEditorModeActive = featureFlags['native_editor'];
    const isPremiumPlusUser = hasRole(UserRole.PremiumPlus, user.roles);
    const isGuestUser = hasRole(UserRole.Gast, user.roles);

    const dispatch = useDispatch();
    const isOneClickModeActive = useOneClickStore((store) => store.isOneClickModeActive);
    const cleanExpressEditorNode = useOneClickStore((store) => store.cleanEditorNode);
    const oneClickEditorNode = useOneClickStore((store) => store.editorNode);
    const expressBufferText = useOneClickStore(selectBufferText);
    const expressRequestsRemaining = useOneClickStore((store) => store.requestsRemaining);
    const { /*plan: expressPlan,*/ limit: expressLimit/*, limitMax: expressLimitMax*/ } = useOneClickStore((store) => store.summary);

    const editorNode = useCorrectionStore((store) => store.editorNode);
    const editorTextSize = useCorrectionStore(store => store.bufferText.length);
    const cleanEditorNode = useCorrectionStore((store) => store.cleanEditorNode);

    const textLength = isNativeEditorModeActive ? editorTextSize : classicTextLength;

    const formattedExpressRequestsRemaining = typeof expressRequestsRemaining === 'number' && formatToThousandSeparator(expressRequestsRemaining, ' ');
    const oneClickTextSize = expressBufferText.length;

    useEffect(() => {
        if (characterLimitReached && textLength <= user.characterLimit && user.characterLimit > 0) {
            setCharacterLimitToastVisibility(false);
            setCharacterLimitReached(false);
        } else if (!characterLimitReached && textLength > user.characterLimit && user.characterLimit > 0) {
            setCharacterLimitToastVisibility(true);
            setCharacterLimitReached(true);
            setCopiedToastVisibility(false);
            setDeleteTextToastVisibility(false);
        }
    }, [characterLimitReached, user.characterLimit, textLength]);

    // const onLimitOverlayClosed = (): void => setCharacterLimitToastVisibility(false);
    const onCopiedOverlayClose = (): void => setCopiedToastVisibility(false);
    const onCopyToClipboard = (): void => {
        sendGTMEvent(gtmEventTypes.copyText);

        const textField = document.createElement('textarea');
        textField.innerHTML = (isOneClickModeActive ? oneClickEditorNode?.innerText : (isNativeEditorModeActive ? editorNode?.innerText : text)) ?? '';
        textField.style.position = 'absolute';
        textField.style.opacity = '0';
        textField.style.top = '0';
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');

        // IE clipboard access check - if you can paste the text, clipboard access was granted
        if (isInternetExplorer()) {
            const tempTextField = document.createElement('textarea');
            tempTextField.style.position = 'absolute';
            tempTextField.style.opacity = '0';
            tempTextField.style.top = '0';
            document.body.appendChild(tempTextField);
            tempTextField.select();
            document.execCommand('Paste');

            const didItWork = tempTextField.value === text;

            if (!didItWork) {
                return;
            }

            document.body.removeChild(tempTextField);

            setCopiedToastVisibility(true);
            setCharacterLimitToastVisibility(false);
            setDeleteTextToastVisibility(false);
        }

        document.body.removeChild(textField);

        setCopiedToastVisibility(true);
        setCharacterLimitToastVisibility(false);
        setDeleteTextToastVisibility(false);
    };

    /**
     * open delete text confirmation toast
     */
    const onDeleteTextStart = (): void => {
        setCopiedToastVisibility(false);
        setCharacterLimitToastVisibility(false);
        setDeleteTextToastVisibility(true);
        sendGTMEvent(gtmEventTypes.clickDelete);
    };

    /**
     * use deleteTextAction and finish the toast
     */
    const onDeleteTextAccept = (): void => {
        dispatch(resetAdviceAction());

        if (isOneClickModeActive) {
            cleanExpressEditorNode();
        } else {
            if (isNativeEditorModeActive) {
                cleanEditorNode();
            } else {
                dispatch(resetPadAction(user.characterLimit));
            }
        }

        onDeleteTextFinish();
        sendGTMEvent(gtmEventTypes.confirmDelete);
    };

    /**
     * close delete text confirmation
     */
    const onDeleteTextFinish = (): void => setDeleteTextToastVisibility(false);

    const getCharacterLimitExceededRoute = (): string => {
        if (DocumentIdentifier.getPlatformIdentifier() === PlatformTypes.lite) {
            return toastRouteLite;
        }

        if (isGuestUser) {
            return process.env.REACT_APP_DUDENDE_URL + '/user/register/private?type=duden-trial&source=mentor';
        }

        if (user.hasUsedTrial || hasFeature('check20kCharacters', user.features)) {
            return process.env.REACT_APP_DUDENDE_URL + '/user/authenticate?destination=/abonnement?source=mentor';
        }

        // When user is logged in and did not use trial
        return toastRouteGeneral;
    };

    const getPointerOffsets = () => {
        return {
            positionDelete: { bottom: 35, right: 19 },
            positionCopy: { bottom: 35, right: 25 },
            deletePointer: { left: 'unset', right: 10 },
            copiedPointer: { left: 'unset', right: 49 },
            wordCounterPointer: { left: 91 },
        };
    };

    const textLengthDigits = textLength.toString().length;
    const offsets = getPointerOffsets();
    const target = [PlatformTypes.lite].includes(DocumentIdentifier.getPlatformIdentifier()) ? '_blank' : '_self';

    const renderPadBanner = () => {
        if (isOneClickModeActive) {
            if (expressRequestsRemaining === 0) {
                const clickHandler: () => void = () => {
                    GTMDataLayerPush({
                        event: 'premium_touchpoint',
                        option: 'mentor_express_limit',
                    });
                };

                return <PadBanner title="Monatslimit erreicht" isExpress>
                    {isPremiumPlusUser ? <SC.CheckoutContent>
                        <SC.CheckoutInfo>Ab nächstem Monat stehen Ihnen wieder {expressLimit} KI-Abfragen zur Verfügung.</SC.CheckoutInfo>
                    </SC.CheckoutContent> : <SC.CheckoutContent>
                        <SC.CheckoutInfo>Ab nächstem Monat stehen Ihnen wieder {expressLimit} KI-Abfragen zur Verfügung.</SC.CheckoutInfo>
                        <p>Oder unbegrenzte KI-Abfragen buchen mit <u>Premium Plus.</u></p>
                        <SC.CheckoutCta target="_blank" onClick={clickHandler} href={`${process.env.REACT_APP_DUDENDE_URL}/user/authenticate?destination=/abonnement%3Fsource=mentor`}>
                            ZU „PREMIUM PLUS“ <ArrowRightIcon />
                        </SC.CheckoutCta>
                    </SC.CheckoutContent>}
                </PadBanner>;
            }
        } else {
            if (characterLimitToastVisibility) {
                const formattedCharacterLimit = formatToThousandSeparator(user.characterLimit, ' ');
                let message = `Sie haben max. ${formattedCharacterLimit} Zeichen pro Prüfung zur Verfügung.`;
                let info = '';
                let cta = '';
                let clickHandler: () => void = () => null;

                // Premium
                if (hasAnyFeature(user.features, 'displayAsPremium', 'displayAsTrial')) {
                    // Premium 20k
                    if (hasFeature('check20kCharacters', user.features) && !hasFeature('check40kCharacters', user.features)) {
                        info = 'Für längere Texte empfehlen wir <u>Mentor Premium mit 40 000 Zeichen.</u>';
                        cta = 'ZU „PREMIUM“';
                    }
                    // Basic
                } else {
                    message = isGuestUser ? `Ohne Nutzerkonto verfügen Sie über max. ${formattedCharacterLimit} Zeichen.` : `Sie verfügen mit Ihrem Basis-Konto über max. ${formattedCharacterLimit} Zeichen.`;
                    cta = 'ZU „PREMIUM“';
                    clickHandler = () => GTMDataLayerPush({ event: 'premium_touchpoint', option: 'character_limit_button' });
                    // Basic after trial
                    if (user.hasUsedTrial) {
                        info = 'Jetzt <u>Premium abonnieren.</u>';
                    } else {
                        info = 'Für längere Texte <u>Premium kostenlos ausprobieren!</u>';
                    }
                }

                return <PadBanner title="Zeichenlimit erreicht">
                    <SC.CheckoutContent>
                        <SC.CheckoutInfo>{message}</SC.CheckoutInfo>
                        {!!info && <p dangerouslySetInnerHTML={{ __html: info }} />}
                        {!!cta && <SC.CheckoutCta data-testid="pad-banner-cta" target={target} href={getCharacterLimitExceededRoute()} onClick={clickHandler}>
                            {cta} <ArrowRightIcon />
                        </SC.CheckoutCta>}
                    </SC.CheckoutContent>
                </PadBanner>;
            }
        }

        return null;
    };

    return (
        <div style={{ position: 'relative' }}>
            {copiedToastVisibility && (
                <ToastOverlay
                    onOverlayClose={onCopiedOverlayClose}
                    positionOffset={offsets.positionCopy}
                    pointerOffset={offsets.copiedPointer}
                    type={toastType.success}
                >
                    Ihr Text wurde in die Zwischenablage kopiert.
                </ToastOverlay>
            )}
            {deleteTextToastVisibility && (
                <ToastOverlay
                    onOverlayClose={onDeleteTextFinish}
                    positionOffset={offsets.positionDelete}
                    pointerOffset={offsets.deletePointer}
                    type={toastType.padded}
                >
                    Sind Sie sicher, dass Sie diesen Text löschen wollen?
                    <div className="toast__bottom">
                        <button className="knob knob--small" onClick={onDeleteTextAccept}>
                            Löschen
                        </button>
                        {' '}
                        <button className="knob knob--small knob--secondary" onClick={onDeleteTextFinish}>
                            Nicht löschen
                        </button>
                    </div>
                </ToastOverlay>
            )}
            <footer className="rim">
                {isOneClickModeActive ? <div>
                    {formattedExpressRequestsRemaining && <SC.ExpressCharsRemaining>{formattedExpressRequestsRemaining}/{expressLimit} KI-Abfragen diesen Monat verbleibend</SC.ExpressCharsRemaining>}
                </div> : <dl className="rim__word-counter">
                    <dd className="rim__word-counter__data">
                        {!characterLimitReached && <>
                            <span
                                className={classNames('rim__word-counter__count', {
                                    [`rim__word-counter__count--digits_${textLengthDigits}`]: true,
                                })}
                            >
                                {formatToThousandSeparator(textLength)}
                            </span>{' '}
                            Zeichen
                        </>}
                    </dd>
                </dl>}
                <div className="rim__actions">
                    {!isOneClickModeActive ? (
                        <>
                            <RimActionButton
                                icon="📋"
                                tooltip="Text in die Zwischenablage kopieren"
                                onClick={onCopyToClipboard}
                                disabled={textLength === 0}
                            />
                            <RimActionButton
                                icon="🗑"
                                tooltip="Text löschen"
                                onClick={onDeleteTextStart}
                                disabled={textLength === 0}
                            />
                        </>
                    ) : (
                        <div className="rim__one_click_actions">
                            <RunCheck />
                            <div>
                                <RimActionButton
                                    icon="📋"
                                    tooltip="Text in die Zwischenablage kopieren"
                                    onClick={onCopyToClipboard}
                                    disabled={oneClickTextSize === 0}
                                />
                                <RimActionButton
                                    icon="🗑"
                                    tooltip="Text löschen"
                                    onClick={onDeleteTextStart}
                                    disabled={oneClickTextSize === 0}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </footer>
            {renderPadBanner()}
        </div>
    );
};
