import React, { forwardRef } from 'react';

import * as SC from './Toggle.styled';
import { InputAppearance } from '../Input';

type ToggleProps = Omit<
    React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    >,
    'type'
> & {
    label?: React.ReactNode;
    appearance?: InputAppearance;
};

// const Toggle: React.FC<ToggleProps> = () => {
//     return <SC.Wrapper>
//         <label className="switch">
//             <input type="checkbox" checked />
//             <span className="slider round"></span>
//         </label>
//     </SC.Wrapper>;
// };

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
    ({ label, appearance, ...toggleProps }, forwardedRef) => {
        const id = toggleProps.id ?? toggleProps.name;

        return (
            <SC.Wrapper>
                <SC.Toggle>
                    <input {...toggleProps} ref={forwardedRef} id={id} type="checkbox" />
                    <span />
                </SC.Toggle>
                {label && <SC.Label>{label}</SC.Label>}
            </SC.Wrapper>
        );
    },
);

export default Toggle;