import styled, { css } from 'styled-components';

import { Colors, Fonts, MediaBreakpoints } from '../../../Styles';

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column-reverse;
    align-items: start;
    gap: 12px;
    min-height: 44px;
    color: ${Colors.mineShaft};
    font-family: ${Fonts.theinhardt};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding: 15px 0 10px;

    @media screen and ${MediaBreakpoints.medium} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 25px 0 5px;
    }
    
    @media screen and ${MediaBreakpoints.large} {
        padding: 30px 0 10px;
    }
`;

export const Info = styled.div`
    display: flex;
    gap: 20px;
    white-space: nowrap;
`;

export const CharactersLength = styled.b<{ isReached?: boolean }>`
    ${({ isReached }) => isReached && css`
        color: ${Colors.crimson};
    `}
`;

export const ActionsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: -10px;
    min-height: 44px;

    @media screen and ${MediaBreakpoints.medium} {
        width: auto;
        margin-left: 0;
    }
`;

export const Actions = styled.div`
    display: flex;
    gap: 20px;
`;

export const CtaWrapper = styled.div`

    @media screen and ${MediaBreakpoints.medium} {
        position: absolute;
        right: 0;
        top: -34px;
    }
`;