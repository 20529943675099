import styled, { css } from 'styled-components';

import { Colors, Fonts } from '../../Styles';

export const Wrapper = styled.div`
    position: relative;
    display: flex;
`;

export const Label = styled.a<{ isActive?: boolean; hideArrow?: boolean }>`
    padding: 12px 15px;
    color: ${Colors.mineShaft};
    font-family: ${Fonts.theinhardt};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    border-radius: 10px;
    border: 1px solid ${(props) => props.isActive ? Colors.mineShaft : '#DADEE0'};
    background-color: ${Colors.white};
    cursor: pointer;

    ${({ hideArrow, isActive }) => !hideArrow && css`
        padding-right: 50px;

        &::after {
            content: ' ';
            position: absolute;
            right: 15px;
            top: 50%;
            border: 4px solid;
            transform: translateY(-${isActive ? 75 : 25}%);
            border-color: ${isActive ? 'transparent' : Colors.mineShaft} transparent ${isActive ? Colors.mineShaft : 'transparent'} transparent;
        }
    `}
`;

export const Title = styled.div`
    padding-bottom: 15px;
    font-weight: bold;
    border-bottom: 1px solid ${Colors.iron};
`;

export const Panel = styled.div<{ alignPanel?: 'left' | 'right' }>`
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    gap: 5px;
    background-color: ${Colors.white};
    top: 55px;
    min-width: 270px;
    max-width: 310px;
    padding: 15px 15px 25px;
    box-shadow: 0 2px 24px 0 rgba(52, 57, 60, 0.32);
    font-family: ${Fonts.theinhardt};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    ${({ alignPanel }) => alignPanel === 'right' && css`right: 0`}
`;

export const Separator = styled.div`
    margin: 0 10px;
    border-bottom: 1px solid ${Colors.iron};
`;

export const Item = styled.div<{ isActive?: boolean }>`
    display: flex;
    justify-content: space-between;
    padding: 7px 10px;
    border-radius: 8px;
    color: ${Colors.mineShaft};
    cursor: pointer;

    &:hover {
        background-color: ${Colors.mystic};
    }

    ${({ isActive }) => isActive && css`
        background-color: ${Colors.mystic};

        .dropdown-check-icon {
            opacity: 1;
        }
    `}
`;

export const ItemCheckIcon = styled.div`
    display: flex;
    align-items: center;
    opacity: 0;
`;

export const ItemDescription = styled.div<{ isActive?: boolean }>`
    color: #636363;
    font-size: 12px;
    line-height: 15px;
`;