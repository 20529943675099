import styled, { css } from 'styled-components';

import { Colors, Fonts, MediaBreakpoints } from '../../Styles';
import { disabledScrollbarStyles } from '../../Styles/Core';

export const Wrapper = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
`;

export const Editor = styled.div<{ selectedId: string | null }>`
    flex: 1 1 auto;
    min-height: 100%;
    color: ${Colors.mineShaft};
    font-family: ${Fonts.theinhardt};
    font-size: 15px;
    line-height: 22px;
    font-style: normal;
    font-weight: 400;
    white-space: pre-wrap;

    &:focus-visible {
        outline: none;
    }

    .issue {
        cursor: pointer;
    }

    &[placeholder]:empty:before {
        content: attr(placeholder);
        display: block;
        margin-bottom: -25vh;
        transition: opacity 1s;
        pointer-events: none;

        color: ${Colors.smokeyGrey};
        font-family: ${Fonts.theinhardt};
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        white-space: normal;
        opacity: 0.8;

        @media screen and ${MediaBreakpoints.medium} {
            font-size: 36px;
            line-height: 46px;
        }
    }

    @media screen and ${MediaBreakpoints.medium} {
        font-size: 22px;
        line-height: 32px;
        padding-bottom: 30px;
    }

    ${({ selectedId }) => selectedId && css`
        #editor-${selectedId} {
            background-color: ${Colors.aliceBlue};

            &.issue {
                background-color: ${Colors.cosmos};
            }
        }
    `}
`;

export const EditorWrapper = styled.div`
    height: 100%;
    position: relative;
    overflow-y: auto;

    ${disabledScrollbarStyles};

    margin-right: 30px;

    @media screen and ${MediaBreakpoints.medium} {
        margin-right: 55px;
    }
    
    @media screen and ${MediaBreakpoints.large} {
        margin-right: 60px;
    }
`;

export const EditorSubWrapper = styled.div`
    display: flex;
    min-height: 100%;
`;

export const CloseIcon = styled.div`
    position: absolute;
    top: -5px;
    right: -10px;
    cursor: pointer;

    @media screen and ${MediaBreakpoints.medium} {
        right: 0;
    }
`;

export const CloseIconWrapper = styled.div`
    position: relative;
`