import { PARAPHRASING_MODE } from '../../../Hooks/useAiStore';
import { DropdownItem } from '../../Dropdown';

export const MODE_ITEMS: DropdownItem[] = [
    {
        name: 'Nur Korrektur',
        description: 'Keine Umformulierungen. Text wird nur korrigiert. ',
        value: null,
        label: 'Schreibstil',
    },
    {
        name: 'separator',
    },
    {
        name: 'Stil beibehalten',
        description: 'Leichte Umformulierungen. Tonalität bleibt erhalten.',
        value: PARAPHRASING_MODE.Neutral,
        label: 'Stil beibehalten',
    },
    {
        name: 'Einfach',
        description: 'Satzbau und Wortwahl werden vereinfacht. Verständlichkeit steht im Vordergrund.',
        value: PARAPHRASING_MODE.Lighter,
        label: 'Einfach',
    },
    {
        name: 'Formell',
        description: 'Gehobenere Formulierungen und Anpassung auf professionelle Kontexte.',
        value: PARAPHRASING_MODE.Formal,
        label: 'Formell',
    },
];

export const TEXT_CATEGORY_ITEMS: DropdownItem[] = [
    {
        name: 'Universell',
        value: 'universell',
        label: 'Universell',
    },
];