import styled from 'styled-components';

import { MediaBreakpoints } from '../../../Styles';

export const Wrapper = styled.div`
    position: absolute;
    inset: auto 15px 15px;
    z-index: 11;

    @media screen and ${MediaBreakpoints.medium} {
        inset: auto 20px 20px auto;
        max-width: 395px;
    }
`;