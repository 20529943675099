import styled, { css } from 'styled-components';

import { Colors, Fonts, MediaBreakpoints } from '../../Styles';

export const Wrapper = styled.div<{ activeId?: string, synonymId?: string }>`
    width: 100%;
    height: calc(100% - 70px);
    overflow-y: auto;
    white-space: pre-wrap;
    font-family: ${Fonts.publico};
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 60px;
    box-sizing: border-box;

    //.text-segment:nth-child(odd) {
    //    background-color: #fffae9;
    //}

    *::selection {
        background-color: ${Colors.supernova};
    }

    &:focus-visible {
        outline: none;
    }

    &[placeholder]:empty:before {
        content: attr(placeholder);
        display: block;
        margin-bottom: -25vh;
        transition: opacity 1s;
        pointer-events: none;

        color: ${Colors.smokeyGrey};
        font-family: ${Fonts.theinhardt};
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        white-space: normal;
        opacity: 0.8;

        @media screen and ${MediaBreakpoints.medium} {
            font-size: 36px;
            line-height: 46px;
        }
    }

    ${(props) => props.activeId && css`
        [data-llm-id="${props.activeId}"] {
            background-position: center bottom;
        }   
    `}

    .synonym * {
        pointer-events: none;
        user-select: none;
        display: contents;
    }

    ${(props) => props.synonymId && css`
        .synonym {
            & > *::selection {
                background-color: ${Colors.pastelGreen};
            }
        }

        .synonym[suggestion] {
            background-color: ${Colors.pastelGreen};

            & > * {
                display: none;
            }
        }

        .synonym[suggestion]:before {
            content: attr(suggestion);
            line-height: normal;
            display: inline;
        }
    `}
`;