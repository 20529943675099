import styled from 'styled-components';

import { Colors, MediaBreakpoints } from '../../Styles';
import { BREAKPOINT } from '../../Styles/MediaBreakpoints';

export const Wrapper = styled.div<{ isLite: boolean }>`
    display: flex;
    flex-direction: column;
    height: ${(props) => props.isLite ? '100vh' : 'calc(100vh - 110px)'};
    width: 100vw;
    align-content: center;
    overflow: hidden;
    max-width: ${BREAKPOINT.b1440}px;
    margin: 0 auto;

    @media screen and ${MediaBreakpoints.medium} {
        flex-direction: row;
        align-content: stretch;
        justify-content: center;
    }
`;

export const Sidebar = styled.aside<{ isFullScreenMode: boolean }>`
    display: ${({ isFullScreenMode }) => isFullScreenMode ? 'none' : 'flex'};
    flex-shrink: 0;
    height: 90px;
    width: 100%;
    padding-bottom: 18px;

    @media screen and ${MediaBreakpoints.medium} {
        display: flex;
        width: 90px;
        height: 100%;
        padding-bottom: 0;
        z-index: 5;
    }
`;

export const Content = styled.main<{ isFullScreenMode: boolean }>`
    display: block;
    width: 100vw;
    height: ${({ isFullScreenMode }) => isFullScreenMode ? '100%' : 'calc(100% - 108px)'};

    @media screen and ${MediaBreakpoints.medium} {
        width: calc(100vw - 90px);
        height: 100%;
    }
`;

export const Advertisement = styled.aside`
    display: none;
    background-color: ${Colors.mystic};
    height: 100%;
    padding: 50px 10px;
    order: 2;

    @media screen and ${MediaBreakpoints.skyscraper} {
        display: block;
    }
`;