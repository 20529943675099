import styled from 'styled-components';

import { Colors, Fonts, MediaBreakpoints } from '../../../Styles';

export const Wrapper = styled.div`
    position: absolute;
    box-sizing: border-box;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    display: flex;
    background-color: ${Colors.cosmos};
    padding: 13px 45px 13px 15px;
    min-height: 44px;

    @media screen and ${MediaBreakpoints.medium} {
        padding: 12px 55px 11px 40px;
        min-height: 48px;
    }
`;

export const Title = styled.span`
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;

    @media screen and ${MediaBreakpoints.medium} {
        font-size: 18px;
        line-height: 25px;
    }
`;

export const Content = styled.div`
    font-family: ${Fonts.theinhardt};
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;

    @media screen and ${MediaBreakpoints.medium} {
        font-size: 15px;
        line-height: 22px;
    }
`;

export const ExpandIcon = styled.div`
    position: absolute;
    right: 0;
    top: 0;

    @media screen and ${MediaBreakpoints.medium} {
        right: 12px;
        top: 2px;
    }
`;

export const CheckoutContent = styled.div`
    font-weight: bold;

    & p {
        margin: 0;
    }
`

export const CheckoutInfo = styled.p`
    padding-top: 5px;
    font-weight: normal;
`

export const CheckoutCta = styled.a`
    padding-top: 15px;
    display: flex;
    text-decoration: none;
`