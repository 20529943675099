import React from 'react';

import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Colors, Fonts, MediaBreakpoints } from '../../Styles';


type AppMenuItemProps = React.PropsWithChildren<{
    indicator?: boolean;
    description?: string;
    href?: string;
    isActive: boolean;
    isDisabled?: boolean;
    iconPath: string;
    type: 'spellcheck' | 'dictionary' | 'extensions' | 'corrector' | 'paraphraser';
    tooltip?: string;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
    text?: string;
}>;

type ItemProps = {
    isActive: boolean;
}

type IconProps = {
    isDisabled?: boolean;
}


const Item = styled.div<ItemProps>`
    margin-bottom: 25px;
    font-size: 18px;

    @media screen and ${MediaBreakpoints.large} {
        margin-bottom: 35px;
    }

    &:last-child {
        margin-bottom: 0
    }

    p {
        margin-left: 12px;
        color: ${Colors.mineShaft};
        font-size: 11px;
        font-family: ${Fonts.theinhardt};
        font-weight: 400;
        line-height: 13px;
        word-wrap: break-word;
    }

    ${ItemProps => ItemProps.isActive && `
      .relative {
        position: absolute;
        top: 0;
        left: 0;
    
        &::before {
          content: '';
          left: 0;
          bottom: -45px;
          position: absolute;
          display: inline-block;
          width: 42px;
          height: 5px;
          background: ${Colors.mineShaft};
        }
      }
    `}
    &:hover {
        .relative {
            position: absolute;
            top: 0;
            left: 0;

            &::before {
                content: '';
                left: 0;
                bottom: -45px;
                position: absolute;
                display: inline-block;
                width: 42px;
                height: 5px;
                background: ${Colors.mineShaft};
            }
        }
    }

    @media screen and ${MediaBreakpoints.medium} {
        ${ItemProps => ItemProps.isActive && `
      .relative {
        position: absolute;
        top: 0;
        left: 0;
    
        &::before {
          content: '';
          left: 0;
          top: -5px;
          position: absolute;
          display: inline-block;
          width: 5px;
          height: 53px;
          background: ${Colors.supernova};
        }
      }
    `}
        &:hover {
            .relative {
                position: absolute;
                top: 0;
                left: 0;

                &::before {
                    content: '';
                    left: 0;
                    top: -5px;
                    position: absolute;
                    display: inline-block;
                    width: 5px;
                    height: 53px;
                    background-color: ${Colors.supernova};
                }
            }
        }
    }


    .description {
        font-size: 11px;
        font-family: ${Fonts.theinhardt};
        line-height: 13px;
    }
`;

Item.displayName = 'item';

const Icon = styled.div<IconProps>`
    display: block;
    text-align: center;
    margin-bottom: -10px;

    ${IconProps => IconProps.isDisabled && `
        opacity: 0.5;
        cursor: default;
  `};

    img {
        display: block;
        margin: 0 auto
    }
`;

const Counter = styled.span`
    background: ${Colors.supernova};
    color: black;
    font-weight: bold;
    height: 16px;
    width: 16px;
    line-height: 16px;
    border-radius: 16px;
    font-size: 12px;
    display: inline-block;
    text-align: center;
    letter-spacing: normal;
    margin: 0;
    flex-shrink: 0;
    position: absolute;
    top: 0;
    right: 8px;
    opacity: 1;
    transition: opacity .25s;
`;

Counter.displayName = 'counter';

export const AppMenuItem: React.FC<AppMenuItemProps> = ({
    isActive,
    type,
    text,
    href,
    tooltip,
    description,
    isDisabled,
    onClick,
    iconPath,
    indicator,
    children
}) => {
    const Component = href ? NavLink : 'button';

    return <Item isActive={isActive}>
        <Component
            className={`launcher__link launcher__type--${type}`}
            to={href ?? ''}
            onClick={onClick}
        >
            {isDisabled !== true && <span className="relative"></span>}
            <Icon isDisabled={isDisabled}>
                <img
                    width="36"
                    src={iconPath}
                    alt={tooltip}
                />
                <div className="description">
                    {description}
                </div>
            </Icon>
            {!!text && <p>{text}</p>}
            {tooltip !== undefined && <span className="launcher__tooltip">{tooltip}</span>}
            {indicator && <Counter />}
        </Component>
        {children}
    </Item>
};
