import React from "react";

export const CheckIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg {...props} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>Check</title>
        <g id="accept">
            <ellipse id="oval" cx="12.9628" cy="12.9291" rx="12.9628" ry="12.9291" fill="#EFF3F5" />
            <path
                id="checkmark"
                d="M9 13.4286L11.5714 16L17.5714 10"
                stroke="#969B9F"
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);
