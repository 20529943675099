import {all, call, put, race, takeLatest, delay, Effect} from 'redux-saga/effects';
import {Action} from 'typescript-fsa';

import {
    CheckSettings,
    initializeUserAction,
    updateCheckSettingsAction,
    updateCheckSettingsFailedAction,
    UpdateCheckSettingsPayload,
    updateCheckSettingsPendingAction,
    updateCheckSettingsSuccessfulAction,
    userDataFailedAction,
    userDataLoadedAction
} from './';
import useAiStore from '../../Hooks/useAiStore';
import useOneClickStore from "../../Hooks/useOneClickStore";
import { apiFetch, RequestMethod } from '../../Util/RequestApi';
import {mapUserFeatureData, ReceivedUserData} from '../../Util/UserUtils';
import {getDictionaryEntriesAction} from '../DictionaryState';

export function* userSaga(): IterableIterator<Effect> {
    yield all([
        takeLatest<Action<void>>(initializeUserAction.type, getUserDataHandler),
        takeLatest<Action<UpdateCheckSettingsPayload>>(updateCheckSettingsAction.type, updateCheckSettingsEventHandler),
    ]);
}

/**
 * handle response for user data
 */
function* getUserDataHandler(): IterableIterator<Effect> {
    try {
        const response = yield call(getUserData);
        if (response.status === 200) {
            const json: ReceivedUserData = yield call([response, response.json]);
            yield put(userDataLoadedAction(mapUserFeatureData(json)));
            useOneClickStore.getState().setHashes(json.email, json.roles);
            useAiStore.getState().handleInitSummary(); // TODO

            if (json.id > 0) {
                yield put(getDictionaryEntriesAction(void (0)));
            }
        }
    } catch (err) {
        yield put(userDataFailedAction(void (0)));
    }
}

/**
 * throw error when connection will take to long
 * @param timeout
 */
function* getUserData(timeout: number = 15000): IterableIterator<Effect> {
    const {response} = yield race({
        response: call(fetchUserData),
        timeout: delay(timeout),
    });

    // call to checkText returned first
    if (response) {
        return response;
    } else {
        throw new Error('Connection timed out.');
    }
}

function fetchUserData(): Promise<Response> {
    return apiFetch('api/userfeatures', { baseUrl: process.env.REACT_APP_GATEKEEPER_URI });
}

function* updateCheckSettingsEventHandler({payload: {settings}}: Action<UpdateCheckSettingsPayload>): IterableIterator<Effect> {
    yield put(updateCheckSettingsPendingAction(void(0)));
    try {
        const response = yield call(updateCheckSettings, settings);

        if (response.status === 204) {
            yield put(updateCheckSettingsSuccessfulAction({settings}));
        } else {
            const errorMessage = JSON.parse(yield call([response, response.text])).message;
            yield put(updateCheckSettingsFailedAction({errorMessage}));
        }
    } catch (err) {
        yield put(updateCheckSettingsFailedAction({errorMessage: err.message}));
    }
}

function* updateCheckSettings(settings: CheckSettings, timeout: number = 15000): IterableIterator<Effect> {
    const {response} = yield race({
        response: call(updateCheckSettingsFetch, settings),
        timeout: delay(timeout),
    })

    if (!response) {
        throw new Error('Connection timed out.');
    }

    return response;
}

function updateCheckSettingsFetch(settings: CheckSettings): Promise<Response> {
    return apiFetch('api/user/settings', { method: RequestMethod.post, body: JSON.stringify(settings)});
}