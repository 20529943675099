import styled from "styled-components";

import { MediaBreakpoints } from "../../Styles";

export const ChangeEditorWrapper = styled.div<{ isLiteMode?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding-bottom: 30px;

  @media screen and ${MediaBreakpoints.medium} {
    padding: 5px 0 30px;
    flex-direction: row;
    gap: ${({ isLiteMode }) => isLiteMode ? 15 : 20}px;
  }

  @media ${MediaBreakpoints.large} {
      padding: 0 0 55px;
  }
`;

export const PopoverWrapper = styled.div`
  position: relative;
`;
