import React, { forwardRef } from 'react';

import * as SC from './Radio.styled';
import { InputAppearance } from '../Input';

type RadioProps = Omit<
    React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    >,
    'type'
> & {
    label?: React.ReactNode;
    message?: React.ReactNode;
    appearance?: InputAppearance;
};

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
    ({ label, message, appearance, ...radioProps }, forwardedRef) => {
        const id = radioProps.id ?? radioProps.name;

        return (
            <SC.Wrapper>
                <SC.Radio>
                    <input {...radioProps} ref={forwardedRef} id={id} type="radio" />
                    <span />
                </SC.Radio>
                {label && (
                    <SC.LabelWrapper>
                        <SC.Label htmlFor={id}>{label}</SC.Label>
                    </SC.LabelWrapper>
                )}
            </SC.Wrapper>
        );
    },
);
