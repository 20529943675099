import * as React from "react";

export const DictionaryIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" {...props}>
        <path
            d="M21.388 4.775c.367-.094.742.085.906.417l.039.094 6.06 17.666c.14.412-.076.853-.47 1.001l-.103.031-.143.032.776 2.056c.143.378-.021.79-.356.976l-.095.045-.103.032-11.226 2.746c-.168.041-.325.08-.472.118-2.283.574-3.824.206-4.476-1.184l-.07-.159-5.368-15.541c-.778-2.267.04-4.33 2.274-5.025l4.518-1.258-.03-.08c-.11-.292-.01-.609.233-.799l.086-.058.096-.048.1-.031 4.503-1.037c.275-.064.56.036.731.248l.06.084.01.021.047.101.06.169 2.413-.617zm4.757 19.667l-4.932 1.09-4.372.988c-.492.113-.947.218-1.366.316-1.616.377-2.372.8-2.382 1.083l.004.041.042.13c.219.546.924.726 2.239.458l.225-.048 11.05-2.707-.508-1.351zM21.072 6.494l-1.553.398 1.943 5.373c.16.44-.146.88-.59.94l-.097.006-.1-.006-.098-.02-2.143-.612-1.557 1.477c-.247.234-.613.259-.894.084l-.09-.065-.082-.08-.074-.11-.044-.094-2.024-5.39-4.49 1.159c-1.315.345-1.827 1.377-1.492 2.706l.048.175 4.778 13.844.016-.01c.426-.274.987-.516 1.69-.734l.31-.092.292-.08 2.976-.705 8.81-2.026-5.535-16.138zm-3.306-.196l-3.074.707 1.978 5.265 1.058-1.005c.1-.094.222-.157.365-.185l.111-.014.127.006.104.022 1.188.339-1.857-5.135z"
            transform="translate(-131 -964) translate(-847 15) translate(868 941) translate(110 8)" />
    </svg>
);

// <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
//     <g fill="none" fill-rule="evenodd">
//         <path fill="#D8D8D8" fill-opacity="0" d="M0 0H36V36H0z" transform="translate(-20 -166) translate(20 166)" />
//         <path fill="#333"
//               d="M22.072 4.776c.383-.098.775.09.946.435l.04.099 6.33 18.45c.147.43-.079.892-.492 1.047l-.107.032-.15.033.812 2.148c.149.395-.023.825-.372 1.019l-.1.046-.107.034-11.725 2.868-.492.123c-2.385.6-3.995.216-4.676-1.236l-.072-.166L6.3 13.476c-.812-2.367.041-4.521 2.374-5.248l4.72-1.314-.031-.083c-.115-.305-.012-.636.242-.835l.09-.06.1-.05.105-.033 4.703-1.083c.288-.067.584.038.764.259l.062.088.012.022.048.105.063.177 2.52-.645zm4.968 20.541l-5.151 1.14-4.567 1.03-1.426.33c-1.688.394-2.477.836-2.488 1.131l.004.044.044.135c.229.57.965.758 2.339.479l.235-.051 11.541-2.827-.531-1.411zM21.74 6.571l-1.622.416 2.03 5.612c.166.46-.153.92-.616.98l-.101.008-.105-.006-.103-.022-2.238-.638-1.626 1.542c-.258.245-.64.27-.933.088l-.094-.068-.086-.084-.078-.115-.046-.098-2.114-5.629-4.688 1.21c-1.375.361-1.909 1.44-1.56 2.827l.051.182 4.99 14.46.017-.01c.445-.287 1.03-.54 1.766-.768l.324-.096.304-.082 3.109-.737 9.201-2.116L21.741 6.57zm-3.453-.204l-3.21.738 2.066 5.5 1.106-1.05c.103-.098.231-.164.38-.193l.116-.015.132.006.11.022 1.24.355-1.94-5.363z"
//               transform="translate(-20 -166) translate(20 166)" />
//     </g>
// </svg>