import { Optimize } from 'library/src/Components/Optimize/Optimize';
import { YieldLoveScript } from 'library/src/Components/Ads/YieldLoveScript';
import { AlertSpace } from 'library/src/Components/AlertSpace/AlertSpace';
import { Header } from 'library/src/Components/Header';
import { AiDesk } from 'library/src/Components/AiDesk';
import { Settings } from 'library/src/Views/Settings';
import { Dictionary } from 'library/src/Views/Dictionary';
import { SpellcheckFull } from 'library/src/Views/SpellcheckFull/SpellcheckFull';
import { AiSpellcheck } from 'library/src/Views/AiSpellcheck';
import { Extensions } from 'library/src/Views/Extensions';
import * as React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectFeatureFlags } from 'library/src/Store/SettingsState';
import { Footer } from 'library/src/Components/Footer';

export const Router: React.FC = () => {
    const featureFlags = useSelector(selectFeatureFlags);
    const mentorDefaultView = featureFlags['mentor_default_view'];
    const showMentorAiView = featureFlags['new_mentor_ai'];

    const isAiDefaultView = showMentorAiView && mentorDefaultView === 'ai';

    return (
        <>
            <Header>
                <AlertSpace />
            </Header>
            <Switch>
                <Route>
                    <AiDesk>
                        <Switch>
                            <Redirect exact from="/" to={`/${isAiDefaultView ? 'ai' : 'classic'}`} />
                            <Route path="/classic" exact component={SpellcheckFull} />
                            {showMentorAiView && <Route path="/ai" exact component={AiSpellcheck} />}
                            <Route path="/dictionary" component={Dictionary} />
                            <Route path="/settings" component={Settings} />
                            <Route path="/extensions" component={Extensions} />
                        </Switch>
                    </AiDesk>
                </Route>
            </Switch>
            <Footer />
            <YieldLoveScript />
            <Optimize />
        </>
    );
};
