import React from 'react';

import * as SC from './Dictionary.styled';
import { DictionaryPanel } from '../../Components/Dictionary/DictionaryPanel';
import { ErrorWrapper } from '../../Util/ErrorWrapper';

const Dictionary: React.FC = () => (
    <SC.Wrapper>
        <ErrorWrapper>
            <DictionaryPanel />
        </ErrorWrapper>
    </SC.Wrapper>
);

export default Dictionary;
