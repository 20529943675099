import React from 'react';

export const CopyAllIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="inherit" {...props}>
        <g>
            <path id="Shape" fillRule="evenodd" clipRule="evenodd"
                  d="M19.5748 5.97593L15.676 2.07754C15.5718 1.97316 15.4305 1.91437 15.283 1.91406H8.59801C8.29047 1.91406 8.04115 2.16338 8.04115 2.47092V5.25599H4.7008C4.55305 5.25588 4.41131 5.3145 4.30679 5.41895C4.20228 5.52339 4.14355 5.66509 4.14355 5.81284V21.4083C4.14355 21.5561 4.20228 21.6978 4.30679 21.8022C4.41131 21.9067 4.55305 21.9653 4.7008 21.9652H15.2822C15.4299 21.9652 15.5716 21.9065 15.676 21.8021C15.7804 21.6977 15.8391 21.556 15.8391 21.4083V18.6233H19.1795C19.487 18.6233 19.7363 18.374 19.7363 18.0664V6.3697C19.7368 6.22222 19.6787 6.0806 19.5748 5.97593ZM15.8391 3.81453L17.8362 5.81167H15.8391V3.81453ZM5.25648 20.8515V6.3697H10.8262V9.71005C10.8262 9.85774 10.8849 9.99938 10.9893 10.1038C11.0938 10.2082 11.2354 10.2669 11.3831 10.2669H14.7234V20.8499L5.25648 20.8515ZM11.9403 7.15763L13.9375 9.15477H11.9403V7.15763ZM15.8391 17.5096V9.71162C15.8389 9.56419 15.7803 9.42285 15.676 9.31864L11.7768 5.41986C11.6726 5.31569 11.5313 5.25706 11.3839 5.25677H9.15526V3.02777H14.725V6.36812C14.725 6.51588 14.7837 6.65758 14.8882 6.76202C14.9928 6.86646 15.1345 6.92508 15.2822 6.92498H18.6226V17.5096H15.8391Z"
                  fill="#333333" />
        </g>
    </svg>
)