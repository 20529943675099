import * as React from 'react';

export const CrossSimpleIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="inherit" {...props}>
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.48404 7.96078L13.0479 3.39432C13.1926 3.24963 13.1926 3.01503 13.0479 2.87034C12.9032 2.72565 12.6686 2.72565 12.5239 2.87034L7.96006 7.43418L3.39361 2.87034C3.32462 2.79993 3.23019 2.76025 3.13162 2.76025C3.03304 2.76025 2.93862 2.79993 2.86963 2.87034C2.72577 3.01538 2.72577 3.24928 2.86963 3.39432L7.43347 7.96078L2.86963 12.5246C2.76413 12.631 2.73272 12.7902 2.78993 12.9287C2.84714 13.0671 2.98181 13.1578 3.13162 13.1586C3.23031 13.1592 3.32496 13.1195 3.39361 13.0486L7.96006 8.48475L12.5239 13.0486C12.5931 13.1187 12.6874 13.1583 12.7859 13.1586C12.8846 13.1592 12.9792 13.1195 13.0479 13.0486C13.1183 12.9796 13.158 12.8852 13.158 12.7866C13.158 12.688 13.1183 12.5936 13.0479 12.5246L8.48404 7.96078Z"
                fill="inherit"
            />
        </g>
    </svg>
);
