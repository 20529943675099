import styled from 'styled-components';

import { Colors, Fonts, MediaBreakpoints } from "../../../Styles";

export const Wrapper = styled.div<{ isLiteMode?: boolean }>`
    display: flex;
    position: absolute;
    z-index: 100;
    box-sizing: border-box;
    font-family: ${Fonts.theinhardt};
    width: 300px;
    top: 160%;
    right: -69%;
    padding: 15px 10px 10px;
    background-color: ${Colors.supernova};
    border-radius: 7px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.10));
    animation: shaking 8s ease-in-out infinite;

    &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid ${Colors.supernova};
        top: -10px;
        left: 50%;
    }

    @media screen and ${({ isLiteMode }) => isLiteMode ? MediaBreakpoints.medium : MediaBreakpoints.large} {
        right: -10%;

        &::before {
            left: 75%;
        }
    }
    
    @media screen and (min-width: 1400px) {
        right: -45%;

        &::before {
            left: 60%;
        }
    }

    @keyframes shaking {
        0% {
            transform: translateX(0);
        }
        1%,4%,7% {
            transform: translateX(-5px);
        }
        2%,5%,8% {
            transform: translateX(0);
        }
        3%,6%,9% {
            transform: translateX(5px);
        }
        10% {
            transform: translateX(0);
        }
    }
`;

export const Image = styled.img`
`;

export const Content = styled.p`
    color: ${Colors.mineShaft};
    font-size: 15px;    
    font-weight: 400;
`;

export const CloseButton = styled.button`
    position: absolute;
    border: none;
    background: transparent;
    padding: 0;
    top: 8px;
    right: 8px;
    cursor: pointer;
`;
