import styled from "styled-components";

import {Fonts, MediaBreakpoints} from "../../Styles";

export const    Wrapper = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 106;
`;

export const ContentWrapper = styled.div`
    display: flex;
    position: relative;
    box-sizing: border-box;
    justify-content: space-between;
    gap: 10px;
    background-color: #FFD0CF;
    flex: 1 0 40%;
    height: 100%;
    padding: 10px 35px 10px 20px;

    @media screen and ${MediaBreakpoints.medium} {
        padding: 10px 45px 10px 90px;
    }

    @media screen and ${MediaBreakpoints.large} {
        padding: 10px 45px 10px 105px;
    }
`;

export const Title = styled.p`
    font-weight: bold;
    margin: 0;
`;

export const Content = styled.div`
    font-family: ${Fonts.theinhardt};
    font-size: 18px;
    line-height: 25px;
`;

export const ImageWrapper = styled.div<{ hasPlaceholder?: boolean }>`
    display: flex;
    flex: 0 0 50px;
    align-items: end;
    margin-bottom: 5px;

    & > img {
        width: 100%;
        padding-top: ${({ hasPlaceholder }) => hasPlaceholder ? 20 : 0}px;
    }

    @media screen and ${MediaBreakpoints.medium} {
        flex-basis: 85px;
    }
`;

export const ExpandIcon = styled.a`
    position: absolute;
    right: 10px;
    top: 10px;

    @media screen and ${MediaBreakpoints.medium} {
        right: 25px;
    }
`;
