import styled, { css } from 'styled-components';

import { Colors, Fonts, MediaBreakpoints } from '../../Styles';
import { disabledScrollbarStyles } from '../../Styles/Core';

export const Wrapper = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
`;

export const EditorWrapper = styled.div`
    height: 100%;
    position: relative;
    overflow-y: auto;
    ${disabledScrollbarStyles};

    margin-right: 30px;

    @media screen and ${MediaBreakpoints.medium} {
        margin-right: 55px;
    }

    @media screen and ${MediaBreakpoints.large} {
        margin-right: 60px;
    }
`;

export const Editor = styled.div<{ selectedId: string | null }>`
    flex: 1 1 auto;
    min-height: 100%;
    color: ${Colors.mineShaft};
    font-family: ${Fonts.theinhardt};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    white-space: pre-wrap;

    &:focus-visible {
        outline: none;
    }
    
    .issue {
        cursor: pointer;
    }

    & .text-segment.loading {
        color: transparent;
        background-color: ${Colors.mystic};
        pointer-events: none;
        text-decoration: none;
        user-select: none;
        animation: blinking 1s infinite;

        * {
            opacity: 0;
        }
    }

    & .text-segment.blured {
        opacity: 0.4;
        cursor: pointer;
    }

    @media screen and ${MediaBreakpoints.medium} {
        font-size: 22px;
        line-height: 32px;
    }

    ${({ selectedId }) => selectedId && css`
        #${selectedId} {
            background-color: ${Colors.aliceBlue};

            &.issue {
                background-color: ${Colors.cosmos};
            }
            
            mark {
                background: none;
                padding-bottom: 0;
            }
        }
    `}
`;

export const EmptyResult = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    p {
        margin: 0;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        text-align: center;
    }
`;