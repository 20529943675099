import React from 'react';

import * as SC from './AiPadCta.styled';
import useAiStore, { MESSAGE_CODE, selectIsSegmentsLoading } from '../../../Hooks/useAiStore';
import { GTMDataLayerPush } from '../../../Util/GoogleTagManager';
import { Knob, KnobAppearance, KnobShape } from '../../Knob';

type AiPadCtaProps = {};

const AiPadCta: React.FC<AiPadCtaProps> = () => {
    const isSegmentsLoading = useAiStore(selectIsSegmentsLoading);
    const handleCorrection = useAiStore((store) => store.handleCorrection);
    const requestsRemaining = useAiStore((store) => store.requestsRemaining);
    const bufferText = useAiStore(store => store.bufferText);
    const isCorrectionFinished = useAiStore(store => store.isCorrectionFinished);
    const charactersLimit = useAiStore(store => store.summary.charactersLimit);

    const checkLabel = isSegmentsLoading ? 'Abbrechen' : 'Text optimieren';
    const isCtaVisible = !!(bufferText.length && !isCorrectionFinished && requestsRemaining !== 0);

    const handleTextCheckClick = () => {
        GTMDataLayerPush({
            event: 'mentor_express_start',
        });

        if (charactersLimit && charactersLimit < bufferText.length) {
            useAiStore.setState({
                error: {
                    code: MESSAGE_CODE.LongText,
                },
            });
        } else {
            void handleCorrection();
        }

    };

    const handleTextCheckStop = () => {
        // GTMDataLayerPush({
        //     event: 'mentor_express_stop',
        // });
        useAiStore.setState((store) => ({
            summary: {
                ...store.summary,
                segmentizeId: null,
            },
        }));
    };

    return isCtaVisible ? (
        <SC.Wrapper>
            <Knob
                onClick={isSegmentsLoading ? handleTextCheckStop : handleTextCheckClick}
                appearance={isSegmentsLoading ? KnobAppearance.Success : KnobAppearance.Primary}
                shape={KnobShape.Curved}
            >
                {checkLabel}
            </Knob>
        </SC.Wrapper>
    ) : null;
};

export default AiPadCta;
