import * as React from 'react';

export const EyeEnabledIcon: React.FC = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="inherit" {...props}>
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1072 5.25635C8.04819 5.25635 3.99688 7.61424 1.26253 11.5441C1.19665 11.6381 1.16152 11.7508 1.16212 11.8663V11.9842C1.16207 12.0984 1.19719 12.2097 1.26253 12.3025C3.99301 16.252 8.04819 18.5902 12.1072 18.5902C16.1663 18.5902 20.2176 16.2323 22.9558 12.3025C23.0211 12.2097 23.0562 12.0984 23.0562 11.9842V11.8663C23.0568 11.7508 23.0216 11.6381 22.9558 11.5441C20.2253 7.61424 16.1701 5.25635 12.1072 5.25635ZM17.5836 11.937C17.5852 14.1913 16.2519 16.2244 14.2056 17.0882C12.1594 17.9519 9.80322 17.4761 8.23618 15.8827C6.66913 14.2893 6.19989 11.8922 7.0473 9.80939C7.89472 7.72661 9.89186 6.36849 12.1072 6.36849C15.1309 6.37065 17.5815 8.86427 17.5836 11.941V11.937ZM2.34391 11.941C3.57785 10.1994 5.17834 8.76015 7.0286 7.72821C5.04675 10.1732 5.04675 13.7048 7.0286 16.1498C5.17934 15.1181 3.57906 13.6804 2.34391 11.941ZM17.1858 16.1498C19.1707 13.7059 19.1707 10.1721 17.1858 7.72821C19.0377 8.75935 20.6396 10.1987 21.8744 11.941C20.6391 13.6817 19.0372 15.1197 17.1858 16.1498ZM12.1072 14.1613C13.3166 14.1613 14.297 13.1637 14.297 11.9331C14.297 10.7025 13.3166 9.70491 12.1072 9.70491C10.8978 9.70491 9.91743 10.7025 9.91743 11.9331C9.91955 13.1628 10.8987 14.1592 12.1072 14.1613ZM12.1072 10.707C12.5949 10.7054 13.0355 11.0031 13.2232 11.4612C13.4109 11.9192 13.3088 12.4472 12.9645 12.7987C12.6202 13.1502 12.1016 13.2558 11.6509 13.0663C11.2001 12.8767 10.9061 12.4294 10.9061 11.9331C10.9103 11.2606 11.4462 10.717 12.1072 10.7149V10.707Z"
                fill="inherit"
            />
        </g>
    </svg>
);