import React, { useCallback } from 'react';

import useAiStore from '../../../Hooks/useAiStore';
import { EyeDisabledIcon, EyeEnabledIcon } from '../../Icons';
import { IconKnob, KnobAppearance, KnobSize } from '../../Knob';
import { Toggle } from '../../Toggle';


type AiResultPadToggleProps = {};

const AiResultPadToggle: React.FC<AiResultPadToggleProps> = () => {
    const isSingleScreenMobileMode = useAiStore((store) => store.isSingleScreenMobileMode);
    const isHighlightModeActive = useAiStore((store) => store.isHighlightModeActive);

    const handleHighlightModeToggle = useCallback(() => {
        useAiStore.setState({ isHighlightModeActive: !isHighlightModeActive });
    }, [isHighlightModeActive]);

    if (isSingleScreenMobileMode) {
        return <IconKnob appearance={KnobAppearance.Secondary} size={KnobSize.Large} icon={isHighlightModeActive ? <EyeEnabledIcon /> : <EyeDisabledIcon />} onClick={handleHighlightModeToggle} />;
    }

    return <Toggle defaultChecked={isHighlightModeActive} label="Änderungen anzeigen" onClick={handleHighlightModeToggle} />;
};

export default AiResultPadToggle;
