import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { renderToStaticMarkup } from 'react-dom/server';

import { BlockContent } from './BlockContent/BlockContent';
import useCorrectionStore, { Segment } from '../../Hooks/useCorrectionStore';
import { normalizeBlocks, setCaretPosition } from '../../Util/grammarcheckHelper';

type CorrectionTextSegmentProps = {
    data: Segment;
    previousKey?: string;
    handleGrammarcheck: () => void;
};

const CorrectionTextSegment: React.FC<CorrectionTextSegmentProps> = ({ data, previousKey, handleGrammarcheck }) => {
    const { id, text, beginOffset, spellAdvices, styleAdvices, isActive } = data;
    const editorNode = useCorrectionStore((store) => store.editorNode);
    const handleSegmentUpdate = useCorrectionStore((store) => store.handleSegmentUpdate);
    const handleSetActiveIndex = useCorrectionStore((store) => store.handleSetActiveIndex);
    const handleSetHighlightedAdvice = useCorrectionStore((store) => store.handleSetHighlightedAdvice);
    const handleStartToCloseInlineAdvice = useCorrectionStore((store) => store.handleStartToCloseInlineAdvice);
    const segmentSuggestion = useCorrectionStore(store => store.adviceSuggestion?.segmentId === id ? store.adviceSuggestion : null);
    const cursorPosition = useCorrectionStore(store => store.cursorPosition);

    const segmentDomInteractions = useRef<{ suggestion?: string }>({});

    const blocks = useMemo(() => {
        return normalizeBlocks(text, [...spellAdvices ?? [], ...styleAdvices ?? []]);
    }, [spellAdvices, styleAdvices, text]);

    const segmentNode = useMemo(() => {
        const spanNode = document.createElement('span');

        spanNode.classList.add('text-segment');

        if (id) {
            spanNode.id = id;
        }

        return spanNode;
    }, [id]);

    const handleAdviceSelect = useCallback((event) => {
        event.preventDefault();

        const node = event.target;

        if (node.nodeName === 'MARK') {
            const adviceId = node.dataset.llmId;

            if (adviceId) {
                handleSetActiveIndex({ id: adviceId });
            }
        }
    }, [handleSetActiveIndex]);

    const handleAdviceHighlite = useCallback((event) => {
        event.preventDefault();

        const node = event.target;

        if (node.nodeName === 'MARK') {
            const adviceId = node.dataset.llmId;

            if (adviceId) {
                node.onmouseleave = handleStartToCloseInlineAdvice;

                handleSetHighlightedAdvice({ id: adviceId });
            }
        }
    }, [handleSetHighlightedAdvice, handleStartToCloseInlineAdvice]);


    // append dom node
    useEffect(() => {
        const previousSegmentNode = previousKey && editorNode?.querySelector(`#${previousKey}`);

        if (previousSegmentNode) {
            previousSegmentNode.insertAdjacentElement('afterend', segmentNode);
        } else {
            editorNode?.insertAdjacentElement('afterbegin', segmentNode);
        }

    }, [editorNode, previousKey, segmentNode]);


    // cleanup dom node
    useEffect(() => {
        const mutationObserver = new MutationObserver((mutationList) => {
            for (const mutation of mutationList) {
                if (
                    mutation.type === 'characterData' ||
                    (
                        mutation.type === 'childList' &&
                        mutation.addedNodes.length === 1 &&
                        mutation.addedNodes[0].nodeName === 'BR'
                    )
                ) {

                    if (segmentNode?.innerText) {
                        handleSegmentUpdate({ id, text: segmentNode.innerText });
                    }
                }
            }
        });

        mutationObserver.observe(segmentNode, { attributes: true, childList: true, subtree: true, characterData: true });

        return () => {
            mutationObserver.disconnect();
            segmentNode?.remove();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editorNode, id]);

    useEffect(() => {
        let intersectionObserver: IntersectionObserver;

        if (editorNode && !isActive) {
            intersectionObserver = new IntersectionObserver((mutationList) => {
                for (const mutation of mutationList) {
                    if (mutation.isIntersecting) {
                        handleSegmentUpdate({ id, text: segmentNode.innerText, isActive: true });
                        handleGrammarcheck();
                    }
                }
            }, {
                root: editorNode,
                rootMargin: '0px',
                threshold: 0.01,
            });

            intersectionObserver.observe(segmentNode);
        }

        return () => {
            intersectionObserver?.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editorNode, id, isActive]);

    useEffect(() => {
        const blocksContent = renderToStaticMarkup(blocks?.length ? <BlockContent blocks={blocks} /> : <>{text}</>);

        segmentNode && (segmentNode.innerHTML = blocksContent);

        if (cursorPosition.segment && segmentNode === cursorPosition.segment) {
            setCaretPosition(segmentNode, cursorPosition.index!);
        } else if (!cursorPosition.segment && typeof cursorPosition.index === 'number' && (beginOffset <= cursorPosition.index) && (beginOffset + text.length >= cursorPosition.index)) {
            setCaretPosition(editorNode, cursorPosition.index);
        }

    }, [beginOffset, blocks, cursorPosition, editorNode, segmentNode, text]);

    useEffect(() => {
        segmentNode?.addEventListener('click', handleAdviceSelect);

        return () => {
            segmentNode?.removeEventListener('click', handleAdviceSelect);
        };
    }, [handleAdviceSelect, segmentNode]);

    useEffect(() => {
        segmentNode?.addEventListener('mouseover', handleAdviceHighlite);

        return () => {
            segmentNode?.removeEventListener('mouseover', handleAdviceHighlite);
        };
    }, [handleAdviceHighlite, segmentNode]);

    useEffect(() => {
        const { id: suggestionId, suggestion } = segmentSuggestion ?? {};

        const interactions = segmentDomInteractions.current;
        const interactionId = interactions.suggestion;
        const hasSuggestion = !!(suggestionId && typeof suggestion !== 'undefined');
        const adviceId = suggestionId ?? interactionId;

        if (hasSuggestion || interactionId) {
            const adviceNode = segmentNode.querySelector(`mark[data-llm-id="${adviceId}"]`);

            if (!adviceNode) return;

            if (hasSuggestion) {
                segmentDomInteractions.current.suggestion = adviceId;
                adviceNode.setAttribute('suggestion', suggestion);
            } else {
                segmentDomInteractions.current.suggestion = undefined;
                adviceNode.removeAttribute('suggestion');
            }
        }
    }, [segmentNode, segmentSuggestion]);

    return null;
};

export default CorrectionTextSegment;