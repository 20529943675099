import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { SynonymCard } from './Cards';
import * as SC from './CorrectionAdvicePanel.styled';
import useCorrectionStore, { ADVICE_TYPE, ERROR_CODE, selectActiveAdvice, selectAdvices } from '../../Hooks/useCorrectionStore';
import { selectFeatureFlags } from '../../Store/SettingsState';
import { selectUserStateFromMainState } from '../../Store/UserState';
import { Colors } from '../../Styles';
import { hasFeature } from '../../Util/UserUtils';
import { StyledAdviceHeader } from '../Advice/AdviceHeader';
import { PremiumTeaser } from '../Advices/Util/PremiumTeaser';
import { CorrectionAdvice } from '../CorrectionAdvice';
import { LoadingDotsIcon } from '../Icons';

type CorrectionAdvicePanelProps = {};

const CorrectionAdvicePanel: React.FC<CorrectionAdvicePanelProps> = () => {
    const user = useSelector(selectUserStateFromMainState);
    const featureFlags = useSelector(selectFeatureFlags);
    const isLoading = useCorrectionStore((store) => Object.values(store.segmentsLoading).some(segmentId => segmentId));
    const textLength = useCorrectionStore(store => store.bufferText.length);
    const correctionMode = useCorrectionStore(store => store.correctionMode);
    const setCorrectionMode = useCorrectionStore(store => store.handleSetCorrectionMode);
    const setActiveIndex = useCorrectionStore(store => store.handleSetActiveIndex);
    const [spellAdvices, styleAdvices] = useCorrectionStore(selectAdvices);
    const currentAdvice = useCorrectionStore(selectActiveAdvice);
    const isSynonymsActive = hasFeature('synonyms', user.features);

    const isNewMentorAi = !!featureFlags['new_mentor_ai'];

    const isDuplicatedStyleAdvice = correctionMode === ADVICE_TYPE.Style && currentAdvice?.errorCode === ERROR_CODE.DuplicatedWord;

    const handleSetCorrectionMode = useCallback(() => {
        setCorrectionMode(correctionMode === ADVICE_TYPE.Style ? ADVICE_TYPE.Spelling : ADVICE_TYPE.Style);
        setActiveIndex({ value: 0 });
    }, [correctionMode, setActiveIndex, setCorrectionMode]);

    const renderSwitchModeSection = () => {
        if (correctionMode === ADVICE_TYPE.Style && spellAdvices.length > 0) {
            return <SC.AdditionalCards>
                <StyledAdviceHeader counterColor={Colors.supernova} onClick={handleSetCorrectionMode}>
                    Rechtschreibung und Grammatik
                    <span>{spellAdvices.length}</span>
                </StyledAdviceHeader>
            </SC.AdditionalCards>;
        }

        if (correctionMode === ADVICE_TYPE.Spelling && styleAdvices.length > 0) {
            return <SC.AdditionalCards>
                <StyledAdviceHeader counterColor={Colors.periwinkle} onClick={handleSetCorrectionMode}>
                    Stil und Vokabular
                    <span>{styleAdvices.length}</span>
                </StyledAdviceHeader>
            </SC.AdditionalCards>;
        }

        return null;
    };

    return <SC.Wrapper>
        {isLoading && <SC.LoadingIcon isShifted={isNewMentorAi}>
            <LoadingDotsIcon fill={Colors.mineShaft} />
        </SC.LoadingIcon>}
        {currentAdvice && <CorrectionAdvice
            data={currentAdvice}
        />}
        {renderSwitchModeSection()}
        {textLength > 0 && <PremiumTeaser />}
        {textLength > 0 && isSynonymsActive && <SynonymCard advice={isDuplicatedStyleAdvice ? currentAdvice : undefined} />}
    </SC.Wrapper>;
};

export default CorrectionAdvicePanel;
