import { useEffect, useLayoutEffect } from 'react';

import useAiStore, { selectIsSegmentsLoading } from './useAiStore';
import { BREAKPOINT } from '../Styles/MediaBreakpoints';

const useSingleScreenMode = () => {
    const isSingleScreenMode = useAiStore(store => store.isSingleScreenMode);

    useLayoutEffect(() => {
        const handleMobileScreenModeValue = () => {
            const isSingleScreenModeValue = window.innerWidth < BREAKPOINT.b1024;
            const isSingleScreenMobileModeValue = isSingleScreenModeValue && window.innerWidth < BREAKPOINT.b768;
            useAiStore.setState({
                isSingleScreenMode: isSingleScreenModeValue,
                isSingleScreenMobileMode: isSingleScreenMobileModeValue,
            });
        };
        window.addEventListener('resize', handleMobileScreenModeValue);
        handleMobileScreenModeValue();
        return () => window.removeEventListener('resize', handleMobileScreenModeValue);
    }, []);

    useEffect(() => {
        if (isSingleScreenMode) {
            const isSegmentsLoading = selectIsSegmentsLoading(useAiStore.getState());

            useAiStore.setState({ isSingleScreenResultMode: isSegmentsLoading });
        } else {
            useAiStore.setState({ isSingleScreenFullMode: false });
        }
    }, [isSingleScreenMode]);
};

export default useSingleScreenMode;