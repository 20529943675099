import * as React from 'react';

export const EyeDisabledIcon: React.FC = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="inherit" {...props}>
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.3091 14.9356L14.9403 11.3044C14.9835 11.5088 15.0071 11.721 15.0071 11.9371C15.0071 13.6309 13.6356 15.0024 11.9418 15.0024C11.7218 15.0024 11.5135 14.9788 11.3091 14.9356ZM5.08429 16.4329L5.89383 15.6233C4.44766 14.6959 3.11938 13.4541 2.00331 11.9372C3.33945 10.1176 4.98604 8.68326 6.77411 7.72831C5.82703 8.87189 5.2572 10.3338 5.2572 11.9372C5.2572 13.179 5.5991 14.3343 6.1925 15.3207L7.00597 14.5073C6.6012 13.737 6.37327 12.8646 6.37327 11.9372C6.37327 8.86403 8.87264 6.36859 11.9418 6.36859C12.8693 6.36859 13.7456 6.60045 14.5159 7.00129L15.6398 5.87736C14.4333 5.46866 13.1876 5.25252 11.9458 5.25252C7.81159 5.25252 3.68527 7.61041 0.906887 11.556C0.83615 11.6542 0.800781 11.7642 0.800781 11.8821V12C0.800781 12.114 0.83615 12.228 0.902957 12.3223C2.08583 14.0003 3.51629 15.3875 5.08429 16.4329ZM18.7994 7.44535L17.9898 8.2549C19.436 9.18234 20.7643 10.4242 21.8804 11.9411C20.5442 13.7606 18.8976 15.195 17.1096 16.1499C18.0566 15.0103 18.6265 13.5444 18.6265 11.9411C18.6265 10.6992 18.2846 9.54388 17.6912 8.55749L16.8777 9.37097C17.2825 10.1412 17.5104 11.0136 17.5104 11.945C17.5104 15.0181 15.011 17.5136 11.9418 17.5136C11.0144 17.5136 10.1381 17.2817 9.3678 16.8809L8.24387 18.0048C9.45033 18.4135 10.6961 18.6296 11.9418 18.6296C16.076 18.6296 20.2023 16.2717 22.9807 12.3262C23.0475 12.2319 23.0829 12.1218 23.0829 12.004V11.8861C23.0829 11.7721 23.0475 11.6581 22.9807 11.5638C21.7978 9.87792 20.3674 8.49069 18.7994 7.44535ZM11.9418 8.87581C10.2481 8.87581 8.87657 10.2473 8.87657 11.9411C8.87657 12.1572 8.90015 12.3694 8.94338 12.5738L12.5745 8.94261C12.3702 8.89938 12.158 8.87581 11.9418 8.87581ZM3.42197 21.2469L21.2477 3.42121C21.4638 3.20507 21.4638 2.85139 21.2477 2.63525C21.0315 2.41911 20.6778 2.41911 20.4617 2.63525L2.636 20.457C2.41986 20.6731 2.41986 21.0268 2.636 21.243C2.74604 21.353 2.88751 21.408 3.02899 21.408C3.17046 21.408 3.31586 21.353 3.42197 21.2469Z"
                fill="inherit"
            />
        </g>
    </svg>
);