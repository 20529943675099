import React, { useCallback, useMemo } from 'react';

import * as SC from './AiStatusMessage.styled';
import useAiStore from '../../../Hooks/useAiStore';
import { AiMessage } from '../../AiMessage';

export const AiStatusMessage: React.FC = () => {
    const segments = useAiStore(store => store.segments);
    const activeSegmentId = useAiStore((store) => store.activeSegmentId);

    const activeSegment = useMemo(() => activeSegmentId
            ? segments.find(s => s.id === activeSegmentId)
            : undefined,
        [activeSegmentId, segments]);

    const showIssueMessage = !!(activeSegment?.issue?.title || activeSegment?.issue?.message);

    const handleClose = useCallback(() => {
        useAiStore.setState({ activeSegmentId: null });
    }, []);

    return (showIssueMessage ?
            <SC.Wrapper>
                <AiMessage title={activeSegment?.issue?.title} handleClose={handleClose}>
                    {activeSegment?.issue?.message}
                </AiMessage>
            </SC.Wrapper> : null
    );
};


export default AiStatusMessage;
