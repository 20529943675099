import React from 'react';

import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import * as SC from './AiDesk.styled';
import useAiStore from '../../Hooks/useAiStore';
import { selectUserFeatures } from '../../Store/UserState';
import { DocumentIdentifier } from '../../Util/DocumentIdentifier';
import { PlatformTypes } from '../../Util/PlatformUtil';
import { hasFeature } from '../../Util/UserUtils';
import { Skyscraper } from '../Ads/Skyscraper';
import { AiMenu } from '../AiMenu';


type AiDeskProps = {
    children?: React.ReactNode;
}

const AiDesk: React.FC<AiDeskProps> = (props) => {
    const features = useSelector(selectUserFeatures);
    const noAdvertisements = hasFeature('noAdvertisements', features);
    const isLiteVersion = DocumentIdentifier.getPlatformIdentifier() !== PlatformTypes.full;
    const isAiRoute = useRouteMatch({ exact: true, path: '/ai' });
    const isSingleScreenFullMode = useAiStore((store) => store.isSingleScreenFullMode);

    const isFullScreenMode = !!isAiRoute && isSingleScreenFullMode;

    return <SC.Wrapper isLite={isLiteVersion}>
        <SC.Sidebar isFullScreenMode={isFullScreenMode}>
            <AiMenu />
        </SC.Sidebar>
        {!isLiteVersion && !noAdvertisements && (
            <SC.Advertisement>
                <Skyscraper />
            </SC.Advertisement>
        )}

        <SC.Content isFullScreenMode={isFullScreenMode}>
            {props.children}
        </SC.Content>
    </SC.Wrapper>;
};

export default AiDesk;