import React from "react";

import * as SC from "./BlockContent.styled";
import { ERROR_CODE, Segment } from "../../../Hooks/useOneClickStore";

type BlockContentProps = {
    blocks: Segment["blocks"];
};

export const BlockContent: React.FC<BlockContentProps> = ({blocks}) => {
    return <>
        {blocks?.map((block, index) => {
            if (block.type === ERROR_CODE.Replace || block.type === ERROR_CODE.Insert || block.type === ERROR_CODE.Remove) {
                return <SC.Mark key={index} data-llm-type={block.type}>{block.copy}</SC.Mark>
            }

            if (block.type === 'common') {
                return <span key={index}>{block.copy}</span>
            }

            if (block.type === ERROR_CODE.Trace) {
                return <SC.Trace key={index} data-llm-type={block.type}>{block.copy}</SC.Trace>
            }

            return null;
        })}
    </>;
};