import styled from 'styled-components';

import { MediaBreakpoints } from '../../../Styles';

export const Wrapper = styled.div`
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    box-sizing: border-box;
    gap: 20px;
    padding: 30px 0;
    z-index: 2;
    min-height: 108px;

    margin-left: -10px;

    @media screen and ${MediaBreakpoints.medium} {
        margin-left: 0;
    }
`;