import React from 'react';

import * as SC from './AiSpellcheck.styled';
import { AiGeneralMessage, AiStatusMessage } from '../../Components/AiMessage';
import { AiPad } from '../../Components/AiPad';
import { AiPadBanner } from '../../Components/AiPad/AiPadBanner';
import { AiResultPad } from '../../Components/AiResultPad';
import useAiScrollSync from '../../Hooks/useAiScrollSync';
import useAiStore from '../../Hooks/useAiStore';
import useSingleScreenMode from '../../Hooks/useSingleScreenMode';

type AiSpellcheckProps = {};

const AiSpellcheck: React.FC<AiSpellcheckProps> = () => {
    const isSingleScreenResultMode = useAiStore(store => store.isSingleScreenResultMode);

    useSingleScreenMode();
    useAiScrollSync();

    return <SC.Wrapper>
        <SC.EditorWrapper isActive={!isSingleScreenResultMode}>
            <AiPad />
            <AiPadBanner />
        </SC.EditorWrapper>

        <SC.ResultWrapper isActive={isSingleScreenResultMode}>
            <AiResultPad />
        </SC.ResultWrapper>

        <AiGeneralMessage />
        <AiStatusMessage />
    </SC.Wrapper>;
};

export default AiSpellcheck;
