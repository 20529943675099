import type { ObjectValues } from '../Util/typesHelper';

export const BREAKPOINT = {
    default: 0,
    b320: 320,
    b650: 650,
    b768: 768,
    b1024: 1024,
    b1280: 1280,
    b1440: 1440,
    b1680: 1680,
    b1920: 1920,
    b2240: 2240,
};
export type Breakpoint = ObjectValues<typeof BREAKPOINT>

export const MediaBreakpoints = {
    mobile: `(min-width: ${BREAKPOINT.b320}px)`,
    small: '(min-width: 400px)',
    medium: `(min-width: ${BREAKPOINT.b768}px)`,
    // tablet: `(min-width: ${BREAKPOINT.b768}px)`,
    large: `(min-width: ${BREAKPOINT.b1024}px)`,
    skyscraper: `(min-width: ${BREAKPOINT.b1280}px)`,
};
