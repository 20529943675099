import React, { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';

import * as SC from './AiPadBanner.styled';
import useAiStore from '../../../Hooks/useAiStore';
import { selectUserStateFromMainState, UserRole } from '../../../Store/UserState';
import { GTMDataLayerPush } from '../../../Util/GoogleTagManager';
import { hasRole } from '../../../Util/UserUtils';
import { ArrowRightIcon, ChevronDownIcon, ChevronUpIcon } from '../../Icons';
import { IconKnob, KnobAppearance, KnobSize } from '../../Knob';

const AiPadBanner: React.FC = () => {
    const user = useSelector(selectUserStateFromMainState);
    const requestsRemaining = useAiStore((store) => store.requestsRemaining);
    const requestsLimit = useAiStore((store) => store.summary.requestsLimit);
    const [isExpanded, setIsExpanded] = useState(true);

    const isGuestUser = hasRole(UserRole.Gast, user.roles);
    const isPremiumPlusUser = hasRole(UserRole.PremiumPlus, user.roles);

    const handleContentExpand = () => {
        setIsExpanded(value => !value);
    };

    const handlePlanUpgrade = useCallback(() => {
        GTMDataLayerPush({
            event: 'premium_touchpoint',
            option: 'mentor_express_limit',
        });
    }, []);

    if (requestsRemaining || typeof requestsRemaining === 'undefined') {
        return null;
    }

    return <SC.Wrapper data-testid="pad-banner">
        <SC.ExpandIcon>
            <IconKnob
                data-testid="pad-banner-close-btn"
                onClick={handleContentExpand}
                icon={isExpanded ? <ChevronDownIcon /> : <ChevronUpIcon />}
                appearance={KnobAppearance.Secondary}
                size={KnobSize.Large}
            />
        </SC.ExpandIcon>
        <SC.Content data-testid="pad-banner-content">
            <SC.Title>Monatslimit erreicht</SC.Title>
            {isExpanded && <SC.CheckoutContent>
                <SC.CheckoutInfo>Ab nächstem Monat stehen Ihnen wieder {requestsLimit} KI-Abfragen zur Verfügung.</SC.CheckoutInfo>
                {!isPremiumPlusUser && <><p>Oder unbegrenzte KI-Abfragen buchen mit <u>Premium Plus.</u></p>
                    <SC.CheckoutCta
                        target="_blank"
                        data-testid="pad-banner-upgrade-btn"
                        onClick={handlePlanUpgrade}
                        href={`${process.env.REACT_APP_DUDENDE_URL}${isGuestUser ? '/service/abos?acq_chnl=dm-ki' : '/user/authenticate?destination=/abonnement%3Fsource=mentor'}`}
                    >
                        ZU „PREMIUM PLUS“ <ArrowRightIcon />
                    </SC.CheckoutCta>
                </>}
            </SC.CheckoutContent>}
        </SC.Content>
    </SC.Wrapper>;
};

export default AiPadBanner;
