import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';

import useOneClickStore/*, { selectBufferText } */ from '../../../Hooks/useOneClickStore';
import { selectFeatureFlags } from '../../../Store/SettingsState';
import { GTMDataLayerPush } from '../../../Util/GoogleTagManager';
import { AiMenuItem } from '../../AiMenu';
import { MenuSpellcheckIcon } from '../../Icons';

type ExpressAppMenuProps = {
}

const ExpressAppMenu: React.FC<ExpressAppMenuProps> = () => {
    const featureFlags = useSelector(selectFeatureFlags);
    const isParaphrasingModeActive = useOneClickStore((store) => store.isParaphrasingModeActive);
    const setIsParaphrasingModeActive = useOneClickStore((store) => store.setIsParaphrasingModeActive);
    const handleCorrection = useOneClickStore((store) => store.handleCorrection);
    // const bufferText = useOneClickStore(selectBufferText);

    const isParaphrasingFeatureActive = featureFlags['paraphrasing'];
    const isParaphrasingItemActive = isParaphrasingFeatureActive && isParaphrasingModeActive;

    const handleToggleParaphrasingMode = useCallback(() => {
        setIsParaphrasingModeActive(!isParaphrasingModeActive);
    }, [isParaphrasingModeActive, setIsParaphrasingModeActive]);

    const handleStartCorrection = useCallback(() => {
        GTMDataLayerPush({
            event: 'mentor_express_start'
        });
        handleCorrection();
    }, [handleCorrection]);

    const handleSpellcheckItemClick = useCallback((event) => {
        event.preventDefault();

        if (!isParaphrasingModeActive) {
            handleStartCorrection()

            return;
        }

        GTMDataLayerPush({ event: 'app-menu', option: 'express_spellcheck' });
        handleToggleParaphrasingMode();
    }, [isParaphrasingModeActive, handleToggleParaphrasingMode, handleStartCorrection]);

    const handleParaphrasingItemClick = useCallback((event) => {
        event.preventDefault();

        if (!isParaphrasingFeatureActive) return;

        if (isParaphrasingModeActive) {
            handleStartCorrection()

            return;
        }

        GTMDataLayerPush({ event: 'app-menu', option: 'express_rephrase' });
        handleToggleParaphrasingMode();
        // if (!bufferText) {
        //     event.preventDefault();
        //
        //     GTMDataLayerPush({ event: 'app-menu', option: 'express_rephrase' });
        //     handleToggleParaphrasingMode();
        // }
    }, [isParaphrasingFeatureActive, isParaphrasingModeActive, handleStartCorrection, handleToggleParaphrasingMode]);

    return <>
        <AiMenuItem
            to="/@"
            isActive={!isParaphrasingItemActive}
            icon={<MenuSpellcheckIcon width={36} height={36} />}
            text="Korrigieren"
            handleClick={handleSpellcheckItemClick}
        />
        <AiMenuItem
            to="/@"
            isActive={isParaphrasingItemActive}
            icon={<MenuSpellcheckIcon width={36} height={36} />}
            text="Umformulieren"
            handleClick={handleParaphrasingItemClick}
        />
        {/*<Popover style={{ position: 'relative' }}>*/}
        {/*    {({ open: isOpen, close: handleClose }) => (*/}
        {/*        <>*/}
        {/*            <Popover.Button as="div" style={{ position: 'relative' }}>*/}
        {/*                <AiMenuItem*/}
        {/*                    to="/_"*/}
        {/*                    isActive={isParaphrasingItemActive}*/}
        {/*                    // isDisabled={!isParaphrasingFeatureActive}*/}
        {/*                    // iconPath="/assets/icons/appmenu/ai-paraphraser.svg"*/}
        {/*                    icon={<MenuSpellcheckIcon width={36} height={36} />}*/}
        {/*                    text="Umformulieren"*/}
        {/*                    // tooltip={isHeaderView || isOpen ? undefined : (isParaphrasingFeatureActive ? 'Umformulieren' : 'Bald verfügbar')}*/}
        {/*                    // description={isHeaderView ? undefined : 'Umformulieren'}*/}
        {/*                    handleClick={handleParaphrasingItemClick}*/}
        {/*                />*/}
        {/*            </Popover.Button>*/}
        {/*            <Transition*/}
        {/*                as={React.Fragment}*/}
        {/*                enter="transition ease-out duration-200"*/}
        {/*                enterFrom="opacity-0 translate-y-1"*/}
        {/*                enterTo="opacity-100 translate-y-0"*/}
        {/*                leave="transition ease-in duration-150"*/}
        {/*                leaveFrom="opacity-100 translate-y-0"*/}
        {/*                leaveTo="opacity-0 translate-y-1"*/}
        {/*            >*/}
        {/*                <SC.PopoverPanel isHeaderView={isHeaderView}>*/}
        {/*                    <ParaphrasingAcceptPopover showArrow={!isHeaderView} onAction={handleToggleParaphrasingMode} handleClose={handleClose} />*/}
        {/*                </SC.PopoverPanel>*/}
        {/*            </Transition>*/}
        {/*        </>*/}
        {/*    )}*/}
        {/*</Popover>*/}
    </>;
};

export default ExpressAppMenu;