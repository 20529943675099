import React from 'react';

import * as SC from './AiMessage.styled';
import type { ObjectValues } from '../../Util/typesHelper';
import { CrossSimpleIcon } from '../Icons/CrossSimpleIcon';
import { IconKnob, KnobAppearance } from '../Knob';

export const MESSAGE_APPEARANCE = {
    Default: 'default',
    General: 'general',
} as const;
export type MessageAppearance = ObjectValues<typeof MESSAGE_APPEARANCE>

type AiMessageProps = {
    title?: string;
    handleClose?: () => void;
    appearance?: MessageAppearance;
};

const AiMessage: React.FC<AiMessageProps> = ({ title, children, appearance, handleClose }) => {
    return <SC.Wrapper appearance={appearance}>
        {!!handleClose && <SC.CloseIcon>
            <IconKnob
                icon={<CrossSimpleIcon width={16} height={16} />}
                appearance={KnobAppearance.Secondary}
                onClick={handleClose}
            />
        </SC.CloseIcon>}
        {!!title && <SC.Title>{title}</SC.Title>}
        {children && <SC.Content>
            {children}
        </SC.Content>}
    </SC.Wrapper>;
};

export default AiMessage;
