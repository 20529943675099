import styled from 'styled-components';

import { Colors, MediaBreakpoints } from '../../../Styles';

export const Mark = styled.mark`
    background-color: transparent;
    background-image: linear-gradient(to top, ${Colors.aliceBlue} 40%, transparent 40%);
    text-decoration: underline dotted 2px;
    text-underline-offset: 4px;
    
    @media screen and ${MediaBreakpoints.medium} {
        text-underline-offset: 5px;
    }
`;

export const Trace = styled.span`
    text-decoration: line-through;
`;