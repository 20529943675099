import styled, { css } from 'styled-components';

import {
    TOOLTIP_POSITION,
    TOOLTIP_APPEARANCE,
    type TooltipAppearance,
    type TooltipPosition,
} from './AiTooltip';
import { Colors, Fonts } from '../../Styles';

type ContentProps = {
    isVisible: boolean;
    position?: TooltipPosition;
    appearance?: TooltipAppearance;
};

const getContentOffset = ({ position, appearance }: ContentProps) => {
    const currentColor = appearance === TOOLTIP_APPEARANCE.Success ? Colors.grannySmithApple : Colors.mineShaft;

    switch (position) {
        case TOOLTIP_POSITION.Right: {
            return css`
                inset: 50% auto auto 100%;
                margin-left: 6px;
                transform: translateY(-50%);

                &::after {
                    right: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    border-color: transparent ${currentColor} transparent transparent;
                }
            `;
        }

        case TOOLTIP_POSITION.Bottom: {
            return css`
                inset: 100% auto auto 50%;
                margin-top: 6px;
                transform: translateX(-50%);

                &::after {
                    bottom: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    border-color: transparent transparent ${currentColor};
                }
            `;
        }

        case TOOLTIP_POSITION.Left: {
            return css`
                inset: 50% 100% auto auto;
                margin-right: 6px;
                transform: translateY(-50%);

                &::after {
                    left: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    border-color: transparent transparent transparent ${currentColor};
                }
            `;
        }

        default: {
            return css`
                inset: auto auto 100% 50%;
                margin-bottom: 6px;
                transform: translateX(-50%);

                &::after {
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    border-color: ${currentColor} transparent transparent;
                }
            `;
        }
    }
};

export const Wrapper = styled.div`
    position: relative;
`;

export const Source = styled.div`
    display: contents;
`;

export const Content = styled.div<ContentProps>`
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
    padding: 6px 8px;
    box-sizing: border-box;
    opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
    transition: visibility 0.2s, opacity 0.2s;
    ${({ isVisible }) =>
            isVisible
                    ? css`
                        opacity: 1;
                        visibility: visible;
                    `
                    : css`
                        opacity: 0;
                        visibility: hidden;
                    `};
    border-radius: 6px;
    background-color: ${
            ({ appearance }) => appearance === TOOLTIP_APPEARANCE.Success
                    ? Colors.grannySmithApple
                    : Colors.mineShaft
    };
    color: ${
            ({ appearance }) => appearance === TOOLTIP_APPEARANCE.Success
                    ? Colors.mineShaft
                    : Colors.mystic
    };
    font-family: ${Fonts.theinhardt};
    font-size: 12px;
    white-space: nowrap;
    text-align: center;
    line-height: 14px;
    letter-spacing: 0.133px;

    ${getContentOffset};

    &::after {
        content: '';
        position: absolute;
        border-width: 5px;
        border-style: solid;
        pointer-events: none;
    }
`;
