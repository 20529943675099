/* see also: https://codepen.io/soophie/pen/oNYbQgd */
export const Colors = {
    white: "#ffffff",
    mystic: "#eff3f5",
    geyser: "#d5dfe2",
    mercury: "#e2e2e2",
    iron: "#dadee0",
    alto: "#dedede",
    silver: "#c1c1c1",
    osloGray: "#969b9f",
    smokeyGrey: "#6c7174",
    rollingStone: "#7D8083",
    tundora: "#454444",
    mineShaft: "#333333",
    black: "#000000",
    supernova: "#ffd008",
    sandyYellow: "#ffef73",
    sun: "#fbba13",
    bajaWhite: "#fff6d0",
    visVis: "#ffedaa",
    ufoGreen: "#44db5e",
    pastelGreen: "#53e25a",
    grannySmithApple: "#8cea9b",
    periwinkle: "#d1d7ff",
    melrose: "#98a6ff",
    crimson: "#e4121e",
    geraldine: "#ff7d7d",
    cosmos: "#ffd0cf",
    cottonBoll: "#e7f1fd",
    aliceBlue: "#E9F7FD",
    darkBlue: "#0312ce",
};