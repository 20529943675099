import styled, { css } from 'styled-components';

import { KnobAppearance, KnobShape, KnobSize } from './Knob';
import { Colors, Fonts, MediaBreakpoints } from '../../Styles';

type ButtonStyledProps = {
    appearance?: KnobAppearance | boolean;
    size?: KnobSize;
    shape?: KnobShape;
};

const KNOB_SIZE = {
    default: 38,
    small: 30,
    large: 44,
} as Record<KnobSize, number>;

const frameStyles = css`
    background-color: inherit;
    color: ${Colors.mineShaft};
    fill: ${Colors.mineShaft};
    border: 2px solid ${Colors.mineShaft};
`;

const primaryStyles = css`
    background-color: ${Colors.supernova};
    color: ${Colors.mineShaft};
    fill: ${Colors.mineShaft};
    border: 0;
`;

const secondaryStyles = css`
    background-color: transparent;
    color: ${Colors.mineShaft};
    fill: ${Colors.mineShaft};
    border: 0;

    &:hover {
        background-color: ${Colors.mystic};
    }
`;

const contrastedStyles = css`
    background-color: ${Colors.mineShaft};
    color: ${Colors.white};
    fill: ${Colors.white};
    border: 0;
`;

const inactiveStyles = css`
    background-color: ${Colors.mystic};
    color: ${Colors.mineShaft};
    fill: ${Colors.mineShaft};
    border: 0;
`;

const successStyles = css`
    background-color: ${Colors.grannySmithApple};
    color: ${Colors.mineShaft};
    fill: ${Colors.mineShaft};
    border: 0;
`;

const getButtonStyles = (appearance?: KnobAppearance | boolean) => {
    switch (appearance) {
        case KnobAppearance.Secondary: {
            return secondaryStyles;
        }

        case KnobAppearance.Inactive: {
            return inactiveStyles;
        }

        case KnobAppearance.Contrasted: {
            return contrastedStyles;
        }

        case KnobAppearance.Frame: {
            return frameStyles;
        }

        case KnobAppearance.Success: {
            return successStyles;
        }

        default: {
            return primaryStyles;
        }
    }
};

export const Button = styled.button<ButtonStyledProps>`
    ${({ appearance }) => getButtonStyles(appearance)};
    height: 38px;
    box-sizing: border-box;
    padding: 0 20px;
    font-family: ${Fonts.theinhardt};
    font-size: 15px;
    white-space: nowrap;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    font-style: normal;
    line-height: 20px;
    cursor: pointer;

    ${({ shape }) => shape === KnobShape.Curved && css`
        border-radius: 100px;
    `}
    &:hover {
        //opacity: 0.75;
    }

    &:disabled {
        //opacity: 0.6;
        cursor: unset;
    }

    @media screen and ${MediaBreakpoints.medium} {
        height: 48px;
        padding: 0 24px;
        line-height: 22px;
    }
`;

export const Icon = styled.div<ButtonStyledProps>`
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    ${({ appearance }) =>
        appearance === KnobAppearance.Inactive
            ? inactiveStyles
            : appearance === KnobAppearance.Contrasted
                ? contrastedStyles
                : appearance === KnobAppearance.Secondary
                    ? secondaryStyles
                    : primaryStyles};
    ${({ appearance }) =>
        appearance === KnobAppearance.Primary
            ? css`box-shadow: 1px 1px 0 1px ${Colors.sun};`
            : appearance === KnobAppearance.Contrasted
                ? css`box-shadow: 1px 1px 0 1px ${Colors.tundora};`
                : css`box-shadow: none;`}
    width: ${({ size }) => (size && KNOB_SIZE[size]) || 38}px;
    height: ${({ size }) => (size && KNOB_SIZE[size]) || 38}px;
    box-sizing: border-box;
    padding: 0;
    border: 0;
    border-radius: 50%;
    font-family: ${Fonts.theinhardt};
`;

export const IconButton = styled.button`
    position: relative;
    background-color: transparent;
    background-repeat: no-repeat;
    outline: none;
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0;
    font-size: inherit;
    border: 0;
    cursor: pointer;
    font-family: ${Fonts.theinhardt};

    &:hover {
        //opacity: 0.75;
    }

    &:disabled {
        //opacity: 0.6;
        cursor: unset;
    }
`;

export const IconLabel = styled.span`
    padding-left: 10px;
`;
