import React from 'react';

import { ERROR_CODE } from '../Hooks/useAiStore';

export const getSanitizedInnerText = (node: HTMLDivElement | null) => {
    const nodeClone = node?.cloneNode(true) as HTMLDivElement | null;

    nodeClone
        ?.querySelectorAll(`[data-llm-type="${ERROR_CODE.Trace}"]`)
        ?.forEach(element => element.remove());

    return nodeClone?.innerText ?? '';
};

export const handleNormalizedEnterPress: React.KeyboardEventHandler<HTMLElement> = (event) => {
    const selection = window.getSelection();
    const range = selection?.getRangeAt(0);

    if (!selection || !range) return;

    const clonedRange = range?.cloneRange();
    const currentNode = range.endContainer as Element;
    const editorNode = event.currentTarget;

    clonedRange.selectNodeContents(editorNode);
    clonedRange.setEnd(currentNode, range.endOffset);

    const currentPosition = clonedRange.toString().length;
    const currentLength = editorNode.innerText.trimEnd().length;

    if (Math.abs(currentLength - currentPosition) < 2) {
        return;
    }

    event.preventDefault();

    range.deleteContents();

    const lineNode = document.createElement('br');

    range.insertNode(lineNode);
    range.setStartAfter(lineNode);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
};